import {LayoutBreakpoint} from './types';

export const mapSizesToProps = ({width}: {width: number}): {layoutBreakpoint: LayoutBreakpoint} => {
  if (width < 768) {
    return {layoutBreakpoint: 'mobile'};
  }

  if (width < 964) {
    return {layoutBreakpoint: 'threecolumn'};
  }

  return {layoutBreakpoint: 'fourcolumn'};
};
