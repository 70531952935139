import React, {useEffect} from 'react';

import {getConfig, storeClose, storeShown} from '../../api/smartBanner';
import {trackEvent, events} from '../../tracking';

import styles from './styles.css';

interface SmartBannerProps {
  onClose: () => void;
}

// make this configurable via props when ios is added, including rating stars
export const SmartBanner = ({onClose}: SmartBannerProps) => {
  const handleClose = () => {
    trackEvent(events.SMART_BANNER_CLOSE);
    storeClose();
    onClose();
  };
  const handleClick = () => {
    trackEvent(events.SMART_BANNER_CLICK);
  };

  useEffect(() => {
    trackEvent(events.SMART_BANNER_IMPRESSION);
    storeShown();
  }, []);

  const config = getConfig();

  if (!config) {
    return null;
  }

  return (
    <div className={styles.banner}>
      <button className={styles.close} onClick={handleClose} />
      <div className={styles.logo} />
      <div className={styles.info}>
        <div className={styles.name}>Newzit</div>
        <div className={styles.rating}>
          <div className={styles.fullStar} />
          <div className={styles.fullStar} />
          <div className={styles.fullStar} />
          <div className={styles.fullStar} />
          <div className={styles.halfStar} />
        </div>
        <div className={styles.price}>{config.price}</div>
      </div>
      <a className={styles.link} href={config.url} rel="noskim" data-google-interstitial="false" onClick={handleClick}>
        View
      </a>
    </div>
  );
};
