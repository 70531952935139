import axios from 'axios';
import shuffle from 'shuffle-array';

import {apiPath, GEO_COUNTRIES, GeoCountry} from './index';

export interface ApiResult {
  orderGB?: number;
  orderUS?: number;
  geo: GeoCountry;
  name: string;
  tld: string;
  url: string;
  thumb: string;
}

interface OrderedApiResult extends ApiResult {
  orderGB: number;
  orderUS: number;
}

interface ThumbnailList {
  all: ApiResult[];
  ordered: {[key: string]: OrderedApiResult[]};
  unordered: {[key: string]: ApiResult[]};
}

let listPromise: Promise<ThumbnailList> | null = null;
let shownThumbnails: string[] = [];

const sortedByGeo = (results: ApiResult[], geo: GeoCountry) => {
  const sortKey = `order${geo.toUpperCase()}` as 'orderGB' | 'orderUS';

  return (results.filter((result) => typeof result[sortKey] === 'number') as OrderedApiResult[]).sort(
    (left, right) => left[sortKey] - right[sortKey]
  );
};

const listThumbnailedDomains = async (): Promise<ThumbnailList> => {
  const response = await axios.get<ApiResult[]>(`${apiPath('/domainThumbs')}`);
  const ordered: {[key: string]: OrderedApiResult[]} = {};
  const unordered: {[key: string]: ApiResult[]} = {};

  for (const geo of GEO_COUNTRIES) {
    const sortKey = `order${geo.toUpperCase()}` as 'orderGB' | 'orderUS';

    ordered[geo] = sortedByGeo(response.data, geo);
    unordered[geo] = response.data.filter(
      (apiResult) => typeof apiResult[sortKey] === 'undefined' && apiResult.geo === geo
    );
  }

  return {
    all: response.data,
    ordered,
    unordered
  };
};

export const clearShownList = () => {
  shownThumbnails = [];
};

export const clearListPromise = () => {
  listPromise = null;
};

interface GetFreshThumbsOptions {
  repeatForever?: boolean;
  sourceGeo?: GeoCountry;
}

const getNotShown = (results: ApiResult[]) => results.filter(({tld}) => !shownThumbnails.includes(tld));

export const getFreshDomainThumbnailUrls = async (
  amount: number,
  {repeatForever = true, sourceGeo = 'gb'}: GetFreshThumbsOptions
): Promise<ApiResult[]> => {
  if (!listPromise) {
    listPromise = listThumbnailedDomains();
  }

  const list = await listPromise;

  let filtered;

  filtered = getNotShown(list.ordered[sourceGeo]);

  if (filtered.length < amount) {
    filtered = filtered.concat(getNotShown(shuffle(list.unordered[sourceGeo], {copy: true})));
  }

  if (filtered.length < amount) {
    filtered = filtered.concat(getNotShown(shuffle(list.all, {copy: true})));
  }

  if (filtered.length < amount && repeatForever) {
    shownThumbnails = Array.from(new Set(filtered.map(({tld}) => tld)));

    return [...filtered, ...(await getFreshDomainThumbnailUrls(amount - filtered.length, {repeatForever, sourceGeo}))];
  }

  filtered = filtered.slice(0, amount);
  shownThumbnails = Array.from(new Set([...shownThumbnails, ...filtered.map(({tld}) => tld)]));

  return filtered;
};
