import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import {debounce} from 'debounce';

import {trackEvent, events} from '../../tracking';

import styles from './styles.css';

const SCROLL_DEBOUNCE = 100;

export const BackToTop = ({isHome = false}) => {
  const [visible, setVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
    trackEvent(events.BACK_TO_TOP);
  };

  useEffect(() => {
    const scrollHandler = debounce(() => {
      const top = window.pageYOffset;
      const viewportHeight = window.innerHeight;

      setVisible(top > viewportHeight * 2);
    }, SCROLL_DEBOUNCE);

    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  return (
    <div className={classnames(styles.container, visible && styles.visible)}>
      {!isHome && (
        <a
          className={classnames(styles.button, styles.backToHome)}
          href="/"
          onClick={() => trackEvent(events.BACK_TO_HOME)}
        >
          Top Stories
        </a>
      )}
      <button className={classnames(styles.button, styles.backToTop)} onClick={scrollToTop}>
        Top
      </button>
    </div>
  );
};
