let apiHost: string;

export type GeoCountry = 'gb' | 'us';
export const GEO_COUNTRIES: GeoCountry[] = ['gb', 'us'];
export type TopStoriesCategory = 'entertainment' | 'news' | 'sport' | 'newYork';
export const TOP_STORIES_CATEGORIES: TopStoriesCategory[] = ['entertainment', 'news', 'sport', 'newYork'];

export const getCategoryKeyByTabAndGeo = (category: TopStoriesCategory, sourceGeo: GeoCountry) =>
  `${category}${sourceGeo.toUpperCase()}`;

export const setApiHost = (host: string) => {
  apiHost = host;
};

export const getApiHost = () => apiHost;

export const apiPath = (path: string) => `${apiHost}${path}`;
