import {getHostname} from './utils';

const STORAGE_KEY = 'tracking-lastNonInternalReferrer';

const getFromStorage = () => {
  try {
    if ('localStorage' in window) {
      const rawValue = window.localStorage.getItem(STORAGE_KEY);

      if (rawValue) {
        return JSON.parse(rawValue);
      }
    }
  } catch (error) {
    // Ignore failures
  }

  return null;
};

const setToStorage = (value: string) => {
  try {
    if ('localStorage' in window) {
      window.localStorage.setItem(STORAGE_KEY, JSON.stringify(value));
    }
  } catch (error) {
    // Ignore failures
  }
};

const isInternal = (url: string) => {
  if (!url) {
    return false;
  }

  return getHostname(location.href) === getHostname(url);
};

export const getLastNonInternalReferrer = () => {
  const referrer = document.referrer;

  if (isInternal(referrer)) {
    return getFromStorage() || null;
  }

  const value = referrer || 'undefined';

  setToStorage(value);

  return value;
};
