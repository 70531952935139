// Third party script
/* tslint:disable */
export const trackIpsos = (section = 'home') => {
  try {
    // @ts-ignore
    window.dm = window.dm || {AjaxData: []};
    // @ts-ignore
    window.dm.AjaxEvent = function(et, d, ssid, ad) {
      // @ts-ignore
      dm.AjaxData.push({et: et, d: d, ssid: ssid, ad: ad});
      // @ts-ignore
      window.DotMetricsObj && DotMetricsObj.onAjaxDataUpdate();
    };

    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://uk-script.dotmetrics.net/door.js?d=${document.location.host}&t=${section}`;

    document.head.appendChild(script);
  } catch (error) {
    // Ignore
  }
};
