export const getUserLocalServerDate = () => {
  const dateObj = new Date();
  const date = `0${dateObj.getDate()}`.slice(-2);
  const month = `0${dateObj.getMonth() + 1}`.slice(-2);
  const year = dateObj.getFullYear();
  const hours = `0${dateObj.getHours()}`.slice(-2);
  const minutes = `0${dateObj.getMinutes()}`.slice(-2);
  const seconds = `0${dateObj.getSeconds()}`.slice(-2);

  return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
};
