import React from 'react';
import classnames from 'classnames';

import MinusCircleSvg from './assets/js/minusCircle.svg';
import styles from './styles.css';

interface Prams {
  handleClick: (e: React.MouseEvent) => void;
  text?: string;
  ariaLabel?: string;
  showText?: boolean;
}

export const LessButton = ({handleClick, text, ariaLabel, showText}: Prams) => {
  return (
    <button
      aria-label={ariaLabel}
      className={classnames(styles.relatedButton, showText && styles.showText)}
      onClick={handleClick}
    >
      {text}
      <MinusCircleSvg />
    </button>
  );
};
