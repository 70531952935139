import React, {useEffect, useState, useRef} from 'react';
import classnames from 'classnames';
import URL from 'url-parse';

import {trackEvent, events} from '../../tracking';
import {ArticleImage, Result} from '../../api/search';
import {ResultItem} from '../ResultItem';
import {Spinner} from '../Spinner';
import {ImageGallery} from '../ImageGallery';
import {jumpToPreviews} from '../IndexPagePreviews';

import styles from './styles.css';

interface ResultListProps {
  debugMode?: boolean;
  results: Result[] | null;
  query: string | null;
  requestMoreResults: () => void;
  noMoreResults: boolean;
}

export const ResultList = ({results, query, requestMoreResults, noMoreResults, debugMode = false}: ResultListProps) => {
  const moreButtonRef = useRef<HTMLButtonElement>(null);
  const [isInViewport, setIsInViewport] = useState(false);
  const [waitingForMoreResults, setWaitingForMoreResults] = useState(false);
  const [gallerySourceIndex, setGallerySource] = useState<number | null>(null);

  const lastIsInViewport = useRef(isInViewport);

  useEffect(() => {
    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          setIsInViewport(true);
        } else {
          setIsInViewport(false);
        }
      }
    };
    const observer = new IntersectionObserver(handleIntersect, {
      rootMargin: '700px'
    });

    setIsInViewport(false);
    setWaitingForMoreResults(false);

    if (moreButtonRef.current) {
      observer.observe(moreButtonRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [results]);

  useEffect(() => {
    if (isInViewport && !lastIsInViewport.current && !noMoreResults) {
      setWaitingForMoreResults(true);
      requestMoreResults();
    }

    lastIsInViewport.current = isInViewport;
  }, [isInViewport]);

  if (!query) {
    return null;
  }

  if (!results) {
    return (
      <div className={classnames(styles.results, styles.loading)}>
        <Spinner />
      </div>
    );
  }

  if (!results.length) {
    return <div className={styles.noResults}>No results found for "{query}"</div>;
  }

  return (
    <div className={styles.results}>
      <div className={styles.resultsHeading}>
        RESULTS
        <button className={styles.liveHomepagesButton} onClick={jumpToPreviews}>
          LIVE homepages
        </button>
      </div>
      {results.map((result, idx) => {
        const trackingParams = {
          channel: new URL(result.url).host,
          id: result.id,
          position: idx + 1,
          query,
          title: result.title,
          url: result.url
        };

        return (
          <div className={styles.wrapper} key={idx}>
            <ResultItem
              onClick={() =>
                trackEvent(events.PAGE_VIEW_EVENT, {...trackingParams, subchannel: 'search_result_article'})
              }
              onSiteClick={() =>
                trackEvent(events.PAGE_VIEW_EVENT, {...trackingParams, subchannel: 'search_result_site'})
              }
              result={result}
              isSmall={true}
            />
            {debugMode && result.articleImages && result.articleImages.length > 0 && (
              <div
                className={styles.img}
                style={{background: gallerySourceIndex === idx ? 'red' : 'green'}}
                onClick={() => setGallerySource(gallerySourceIndex === idx ? null : idx)}
              >
                img
              </div>
            )}
            {debugMode && gallerySourceIndex === idx && result.articleImages && (
              <ImageGallery urls={(result.articleImages as ArticleImage[]).map(({sourceUrl}) => sourceUrl)} />
            )}
          </div>
        );
      })}
      {!noMoreResults && (
        <button className={styles.moreButton} ref={moreButtonRef}>
          {waitingForMoreResults ? 'LOADING ...' : 'More ...'}
        </button>
      )}
    </div>
  );
};
