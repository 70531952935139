import React from 'react';
import classnames from 'classnames';

import picture1 from './assets/picture1.jpg';
import picture2 from './assets/picture2.jpg';
import picture3 from './assets/picture3.jpg';
import picture4 from './assets/picture4.jpg';
import picture5 from './assets/picture5.jpg';
import styles from './styles.css';

export interface AboutOverlayProps {
  open: boolean;
  onClose: () => void;
}

export const AboutOverlay = ({open, onClose}: AboutOverlayProps) => {
  if (!open) {
    return <div className={styles.container} />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.overlay}>
        <div className={styles.heading}>Welcome to this beta version of Newzit!</div>
        <div className={styles.text}>
          Newzit is a new and unbiased search and aggregation tool for bona fide news sites ONLY.
        </div>
        <div className={styles.text}>
          Content is rated by our algorithm purely for its relevance and recency, regardless of political orientation.
        </div>
        <div className={styles.text}>Every news organisation has its own biases, even the ones who say they don’t.</div>
        <div className={styles.text}>But with Newzit you will always get both sides of a story.</div>
        <div className={styles.text}>
          You might see articles that upset you but there is no fake news, because we won’t index the sites who produce
          it.
        </div>
        <div className={styles.text}>
          Pay-walled sites are treated the same as everyone else, although their content will be flagged for users.
        </div>

        <div className={styles.pictureGroupContainer}>
          <div className={styles.pictureGroup}>
            <div className={styles.picture}>
              <img src={picture1} alt="Search bar and Top Stories" />
            </div>
            <div className={styles.text}>
              You can either use the search bar or browse the day’s 60 Top Stories in the US and UK, picked strictly by
              algorithm.
            </div>
          </div>
          <div className={styles.pictureGroup}>
            <div className={styles.picture}>
              <img src={picture2} alt="See More button" />
            </div>
            <div className={styles.text}>
              By clicking the See More button for each group you will be shown the same story as covered by other sites.
            </div>
          </div>
          <div className={styles.pictureGroup}>
            <div className={styles.picture}>
              <img src={picture3} alt="US and UK" />
            </div>
            <div className={styles.text}>
              Top Stories are presented for the US and UK. You can toggle between the two by clicking the relevant flag
              at the top of the page.
            </div>
          </div>
          <div className={styles.pictureGroup}>
            <div className={styles.picture}>
              <img src={picture4} alt="Ticker" />
            </div>
            <div className={styles.text}>
              You can also click on any of the ‘most-searched’ terms of the moment in the ‘ticker tape’ at the top of
              the page.
            </div>
          </div>
          <div className={classnames(styles.pictureGroup, styles.wideGroup)}>
            <div className={styles.picture}>
              <img src={picture5} alt="Homepage thumbnails" />
            </div>
            <div className={styles.text}>
              Thumbnails of popular home pages for the US and UK are included so you can see at a glance what is going
              on in your country and visit those sites directly by clicking the relevant page.
            </div>
          </div>
        </div>

        <div className={styles.text}>In time the home pages you visit most often will be presented to you first.</div>
        <div className={styles.text}>So far we have indexed 900+ US sites and 200+ UK sites.</div>
        <div className={styles.text}>
          Shortly we will be adding news sites from Australia, New Zealand and Canada with Top Story options for those
          countries to follow.
        </div>
        <div className={styles.text}>
          We will also be adding Top Story tabs for Sports, Finance and Showbiz in the coming weeks.
        </div>
        <div className={styles.text}>
          Currently Newzit is only available in a desktop version although you can still access it from your mobile
          device.
        </div>
        <div className={styles.text}>
          Mobile web and app versions will be released once we have completed this beta phase of testing.
        </div>
        <div className={styles.text}>In the meantime we would welcome your feedback via the email link below.</div>
        <div className={styles.text}>
          Or if you are an operator of (or simply a fan of) a news site you think should be included please let us know.
        </div>
        <div className={styles.text}>
          Thank you and please enjoy Newzit, where you’ll never miss a story that matters.
        </div>
        <div className={styles.text}>
          <div className="emailImage" />
        </div>

        <a className={styles.closeLink} onClick={onClose}>
          Close
        </a>
        <button className={styles.closeButton} onClick={onClose} aria-label="Close"></button>
      </div>
    </div>
  );
};
