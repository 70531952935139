import {getUserLocation} from '../api/location';
import {loadScript} from '../utils';

import './responsive.css';
import './theme.css';

export const GDPR_SCRIPT = 'https://cmp.dmgmediaprivacy.co.uk/2.8.23-newzit.1/iife/mol-ads-cmp.min.js';
export const CCPA_SCRIPT = 'https://cmp.dmgmediaprivacy.co.uk/2.8.18-newzit-ccpa-6/iife/mol-ads-cmp.min.js';
export const LOCATION_FILE = '/locationjson.html';

const CCPA_SETTINGS_LINK_SELECTOR = '[data-ccpa-settings-link]';

interface WindowWithCriteria extends Window {
  PageCriteria?: {
    geo?: string;
    region?: string;
  };
}

interface WindowWithCMPPlaceholders extends Window {
  __cmp?: {a: string[][]};
  __uspapi?: {a: string[][]};
}

const getProvisional = (globalKey: '__cmp' | '__uspapi') => {
  /* tslint:disable-next-line */
  const provisional = function(command: string, parameter: string, callback: string) {
    // @ts-ignore
    window[globalKey].a.push([command, parameter, callback]);
  };

  // @ts-ignore
  provisional.a = [];

  return provisional;
};

const hasGDPRConsentLocalStorage = () => {
  try {
    return Boolean(window.localStorage.getItem('euconsent'));
  } catch (error) {
    return false;
  }
};

const bindCCPASettingsLinks = () => {
  const links: HTMLElement[] = Array.from(document.querySelectorAll(CCPA_SETTINGS_LINK_SELECTOR));

  for (const link of links) {
    link.addEventListener('click', (event) => {
      event.preventDefault();

      const uspapi = (window as WindowWithCMPPlaceholders).__uspapi;

      if (uspapi) {
        uspapi.a.push(['openSettings']);
      }
    });
  }
};

const bindTooltipWorkaround = () => {
  document.addEventListener('click', (event) => {
    const triggerEl = event.target as HTMLElement;

    if (!triggerEl || !triggerEl.closest('.mol-ads-cmp--tooltip')) {
      return;
    }

    const container = triggerEl.closest('.mol-ads-cmp--tooltip') as HTMLElement;
    const content = container.querySelector('.mol-ads-cmp--tooltipcontent');

    if (!content) {
      return;
    }

    if (triggerEl.closest('.mol-ads-cmp--tooltip-trigger')) {
      const alreadyOpen = Array.from(document.querySelectorAll('.mol-ads-cmp--tooltip.triggeredtooltip'));

      for (const openTooltip of alreadyOpen) {
        openTooltip.classList.remove('triggeredtooltip');
      }

      container.classList.add('triggeredtooltip');
    } else if (triggerEl.getAttribute('href') === '#close') {
      container.classList.remove('triggeredtooltip');
    }
  });
};

export const loadIfNeeded = async ({render = true} = {}) => {
  try {
    const data = await getUserLocation();

    if (!data) {
      throw new Error('No location could be established');
    }

    const isCCPA = data.Country === 'US';

    (window as WindowWithCriteria).PageCriteria = {
      geo: data.Country,
      region: data.REGION_CODE
    };

    if (!data.INEU && !isCCPA) {
      return false;
    }

    if (data.INEU && !hasGDPRConsentLocalStorage()) {
      return false;
    }

    const params: {[key: string]: string} = {
      id: 'mol-ads-cmp'
    };

    if (render) {
      params['data-render'] = 'true';
    }

    if (isCCPA && !(window as WindowWithCMPPlaceholders).__uspapi) {
      (window as WindowWithCMPPlaceholders).__uspapi = getProvisional('__uspapi');
    } else if (!isCCPA && !(window as WindowWithCMPPlaceholders).__cmp) {
      (window as WindowWithCMPPlaceholders).__cmp = getProvisional('__cmp');
    }

    await loadScript(isCCPA ? CCPA_SCRIPT : GDPR_SCRIPT, params);

    if (data.Country === 'US' && data.REGION_CODE === 'CA') {
      bindCCPASettingsLinks();
    }

    bindTooltipWorkaround();

    return true;
  } catch (error) {
    // Do not load
    return false;
  }
};
