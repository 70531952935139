import * as events from '../events';
import {Metadata} from '..';

import {pageView, event} from './gtag';
import {getWebPushStatus} from './meta/webPush';

// let tabViewCount = 0;

export const sendEventToGoogleAnalytics = (eventName: string, metadata?: Metadata) => {
  if (eventName === events.TAB_VIEW_EVENT) {
    // tabViewCount++;
    // const isFirstTabView = tabViewCount <= 1;

    if (metadata && !metadata.query) {
      const channel = `${metadata.tab} ${metadata.geo}`;

      if (metadata.autoRefresh) {
        return event('autorefresh channel', {
          autoRefreshIncrease: 1,
          content_group1: 'channel',
          event_category: 'auto refresh'
        });
      }

      return pageView({
        channel,
        content_group1: 'channel'
      });
    }

    if (metadata && metadata.query) {
      return event('search', {
        content_group1: 'search',
        event_category: 'internal search',
        event_label: metadata.query as string,
        internalSearchIncrease: 1
      });
    }

    return;
  }

  if (eventName === events.PAGE_VIEW_EVENT && metadata) {
    const articleFindingMethod = metadata.subchannel as string;
    const brandDomain = metadata.channel as string;
    const destinationUrl = metadata.url as string;
    const parentArticleTitle = (metadata.parentTitle as string) || undefined;
    let eventCategory = 'main article';
    let contentGroup = 'topstory';

    if (parentArticleTitle) {
      eventCategory = 'related article';
    }

    if (articleFindingMethod.startsWith('indexpage')) {
      eventCategory = 'homepage screenshot';
      contentGroup = 'homepage screenshot';
    }

    if (articleFindingMethod.startsWith('search')) {
      eventCategory = 'search item';
      contentGroup = 'search';
    }

    return event('click', {
      articleFindingMethod,
      brandDomain,
      content_group1: contentGroup,
      destinationUrl,
      event_category: eventCategory,
      event_label: `rank position ${metadata.position}`,
      parentArticleTitle
    });
  }

  if (eventName === 'push' && metadata) {
    const sharedProps = {
      event_category: 'push notification',
      webPushStatus: getWebPushStatus()
    };

    if (metadata.type === 'notification' && metadata.action === 'click') {
      return event('click', {
        ...sharedProps,
        event_label: `${metadata.campaignId}:${metadata.subscriptionId}`,
        non_interaction: false,
        webPushCampaignId: metadata.campaignId as string
      });
    }

    if (metadata.type === 'prompt') {
      if (metadata.action === 'impression') {
        return event('prompt impression', {
          ...sharedProps,
          non_interaction: true
        });
      }

      if (metadata.action === 'click') {
        return event('prompt choice', {
          ...sharedProps,
          event_label: metadata.permission === 'default' ? 'closed' : (metadata.permission as string),
          non_interaction: false
        });
      }
    }
  }

  if (eventName === events.FLAG_SELECT) {
    return event('change', {
      event_category: 'geo icon',
      event_label: metadata && (metadata.geo as string)
    });
  }

  if (eventName === events.SOCIAL_SHARE_FACEBOOK) {
    return event('click', {
      event_category: 'social share',
      event_label: 'facebook_share_site',
      socialShareClicksIncrease: 1
    });
  }

  if (eventName === events.SOCIAL_SHARE_TWITTER) {
    return event('click', {
      event_category: 'social share',
      event_label: 'twitter_share_site',
      socialShareClicksIncrease: 1
    });
  }

  if (eventName === events.SOCIAL_FOLLOW_FACEBOOK) {
    return event('click', {
      event_category: 'social follow',
      event_label: 'facebook_follow_site',
      socialShareClicksIncrease: 1
    });
  }

  if (eventName === events.SOCIAL_FOLLOW_TWITTER) {
    return event('click', {
      event_category: 'social follow',
      event_label: 'twitter_follow_site',
      socialShareClicksIncrease: 1
    });
  }

  if (
    [events.SOCIAL_SHARE_TOPSTORY_FACEBOOK, events.SOCIAL_SHARE_TOPSTORY_FACEBOOK_OVERRIDE].includes(eventName) &&
    metadata
  ) {
    const articleFindingMethod = metadata.subchannel as string;
    const brandDomain = metadata.channel as string;
    const destinationUrl = metadata.url as string;

    return event('click', {
      articleFindingMethod,
      brandDomain,
      destinationUrl,
      event_category: 'social share',
      event_label: `rank position ${metadata.position}`,
      socialShareClicksIncrease: 1
    });
  }

  return;
};
