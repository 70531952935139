import {TOP_STORIES_CATEGORIES, TopStoriesCategory} from './api';

const STORAGE_PREFIX = 'newzit-';

type SerializableType = string | boolean | number | null;

export const setToStorage = (key: string, value: SerializableType) => {
  try {
    if ('localStorage' in window) {
      window.localStorage.setItem(`${STORAGE_PREFIX}${key}`, JSON.stringify(value));
    }
  } catch (error) {
    // Ignore failures
  }
};

export const getFromStorage = (key: string): SerializableType => {
  try {
    if ('localStorage' in window) {
      const rawValue = window.localStorage.getItem(`${STORAGE_PREFIX}${key}`);

      if (rawValue) {
        return JSON.parse(rawValue);
      }

      return null;
    }
  } catch (error) {
    // Ignore failures
  }

  return null;
};

export const hasVisitedBefore = () => {
  try {
    if ('localStorage' in window) {
      const rtaStore = window.localStorage.getItem('rta2');

      if (rtaStore) {
        return true;
      }
    }
  } catch (error) {
    // Ignore failures
  }

  return Boolean(getFromStorage('has-visited-before'));
};

export const CATEGORY_STORAGE_KEY = 'category';

export const getCategoryFromStorage = () => {
  const value = getFromStorage(CATEGORY_STORAGE_KEY) as string;

  if ((TOP_STORIES_CATEGORIES as string[]).includes(value)) {
    return value as TopStoriesCategory;
  }

  return null;
};

const AB_KEY_NAME = 'nabt';
const AB_KEY_MAX = 60;

export const setABToStorage = (override?: string) => {
  try {
    const value = override || (getFromStorage(AB_KEY_NAME) as string) || Math.floor(Math.random() * AB_KEY_MAX);

    setToStorage(AB_KEY_NAME, String(value));

    return String(value);
  } catch (error) {
    // Ignore error
    return null;
  }
};
