const STORAGE_KEY = 'mol.ads.cmp.userConsentDegree';
const CONSENT_DEGREE_NAMES: {[key: string]: string} = {
  no: 'disabled',
  partial: 'partial',
  yes: 'enabled'
};

const getConsentDegree = () => {
  let consentDegree;

  try {
    consentDegree = window.localStorage.getItem(STORAGE_KEY);
  } catch (error) {
    // Ignore
  }

  return consentDegree;
};

export const getGdprStatus = () => {
  const consentDegree = getConsentDegree();

  if (!consentDegree) {
    return 'none';
  }

  return CONSENT_DEGREE_NAMES[consentDegree] || consentDegree;
};
