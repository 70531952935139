import {getExternalAppState} from './externalAppState';
import {pushToAdsQueue} from './adsQueue';

export const setGlobalClasses = () => {
  try {
    // tslint:disable-next-line: no-any
    const adsConfig = (window as any).getAdsConfig();

    if (adsConfig.adsDescriptions.stickyBillboard) {
      const width = window.innerWidth;

      if (width >= 768 && width < 1264) {
        document.body.classList.add('hasStickyLeaderboard');
        document.body.classList.remove('hasStickyBillboard');
      } else if (width >= 1264) {
        document.body.classList.add('hasStickyBillboard');
        document.body.classList.remove('hasStickyLeaderboard');
      }
    } else {
      document.body.classList.remove('hasStickyBillboard');
      document.body.classList.remove('hasStickyLeaderboard');
    }
  } catch (error) {
    // Ignore - ads will deal with it
  }
};

export const mountAd = (slotId: string, position: string) => {
  pushToAdsQueue({
    data: {
      appState: getExternalAppState() || 'home',
      position,
      slotId
    },
    scope: 'adSlotMount'
  });
};

export const unmountAd = (slotId: string, position: string) => {
  pushToAdsQueue({
    data: {
      appState: getExternalAppState() || 'home',
      position,
      slotId
    },
    scope: 'adSlotUnmount'
  });
};

export const updateAd = (slotId: string, position: string) => {
  if (slotId === 'stickyBillboard') {
    setGlobalClasses();
  }

  pushToAdsQueue({
    data: {
      appState: getExternalAppState() || 'home',
      position,
      slotId
    },
    scope: 'adSlotUpdate'
  });
};

interface WindowWithMVTest extends Window {
  molMVTest?: string;
}

export const setAdExperiments = (abExperiments: string[] | null) => {
  if (abExperiments && Array.isArray(abExperiments)) {
    (window as WindowWithMVTest).molMVTest = abExperiments.join(',');
  }
};
