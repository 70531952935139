import React, {useEffect, useState} from 'react';

import styles from './styles.css';

const reload = () => window.location.reload();

export const OfflinePopUp = () => {
  const [isOffline, setIsOffline] = useState(false);
  const [ignored, setIgnored] = useState(false);

  const checkOnlineStatus = () => {
    if (navigator.onLine) {
      setIgnored(false);
    }
    setIsOffline(!navigator.onLine);
  };

  useEffect(() => {
    if (!('onLine' in navigator)) {
      return;
    }

    checkOnlineStatus();
    window.addEventListener('online', checkOnlineStatus);
    window.addEventListener('offline', checkOnlineStatus);

    return () => {
      window.removeEventListener('online', checkOnlineStatus);
      window.removeEventListener('offline', checkOnlineStatus);
    };
  }, []);

  if (!isOffline || ignored) {
    return null;
  }

  return (
    <div className={styles.popup}>
      <div className={styles.headingWrapper}>
        <div className={styles.heading}>You're currently offline.</div>
        <button className={styles.reload} aria-label="reload" onClick={reload}></button>
      </div>
      <div className={styles.description}>Please check your network connection and try again</div>
      <button className={styles.close} aria-label="close" onClick={() => setIgnored(true)}></button>
    </div>
  );
};
