import URL from 'url-parse';

import {getFromStorage, setToStorage} from '../storage';

const ENABLED_ANDROID = true;
const ENABLED_IOS = true;
const SMART_BANNER_KEY = 'smart-banner';
const SMART_BANNER_LASTSHOWN_KEY = 'smart-banner-lastshown';
const WAIT_TIME_AFTER_CLOSED = 24 * 60 * 60 * 1000;

const detectAndroid = () => {
  const ua = window.navigator.userAgent;

  return ua.match(/android/i) && ua.match(/mobile/i) && !ua.match(/like android/i);
};

const detectIphone = () => window.navigator.userAgent.match(/iphone/i);

export const shouldShow = () => {
  const parsedUrl = new URL(window.location.href, true);

  if (parsedUrl.query && parsedUrl.query.debugSmartBanner) {
    return true;
  }

  const isAndroid = detectAndroid();
  const isIOS = detectIphone();

  if (!isAndroid && !isIOS) {
    return false;
  }

  if ((isAndroid && !ENABLED_ANDROID) || (isIOS && !ENABLED_IOS)) {
    return false;
  }

  const lastClosed = getFromStorage(SMART_BANNER_KEY) as number | null;
  const now = Date.now();

  if (lastClosed && now - lastClosed < WAIT_TIME_AFTER_CLOSED) {
    return false;
  }

  return true;
};

export const storeClose = () => {
  setToStorage(SMART_BANNER_KEY, Date.now());
};

export const storeShown = () => {
  setToStorage(SMART_BANNER_LASTSHOWN_KEY, Date.now());
};

export const getConfig = () => {
  const isAndroid = detectAndroid();
  const isIOS = detectIphone();

  if (isAndroid) {
    return {
      price: 'FREE - In Google Play',
      url:
        'https://play.google.com/store/apps/details?id=com.mailmedia.newzit&referrer=utm_source%3Dnewzit%26utm_medium%3Dsmartbanner'
    };
  }

  if (isIOS) {
    return {
      price: 'FREE - On the App Store',
      url: 'https://apps.apple.com/app/apple-store/id1494294684?pt=120907073&ct=newzit&mt=8'
    };
  }

  return null;
};

export interface DebugInfoSmartBannerData {
  isAndroid: boolean;
  isIOS: boolean;
  isToShow: boolean;
  lastClosed: number | null;
  now: number;
  remaining: number | null;
}

export const getDebugInfo = (): DebugInfoSmartBannerData => {
  const isAndroid = Boolean(detectAndroid());
  const isIOS = Boolean(detectIphone());
  const lastClosed = getFromStorage(SMART_BANNER_KEY) as number | null;
  const now = Date.now();
  const remaining = lastClosed ? WAIT_TIME_AFTER_CLOSED - (now - lastClosed) : null;
  const isToShow = shouldShow();

  return {
    isAndroid,
    isIOS,
    isToShow,
    lastClosed,
    now,
    remaining
  };
};
