import React, {useState, useEffect} from 'react';
import URL from 'url-parse';
import classnames from 'classnames';
import Cookies from 'js-cookie';
import withSizes from 'react-sizes';

import {trackEvent, events} from '../../tracking';
import {GeoCountry, TopStoriesCategory, GEO_COUNTRIES} from '../../api';
import {search, Result} from '../../api/search';
import {clearShownList} from '../../api/domainThumbnails';
import {setExternalAppState, setExternalAppGeo, setExternalAppTab} from '../../api/externalAppState';
import {getUserLocation, LocationResponse} from '../../api/location';
import {DebugInfoSmartBannerData} from '../../api/smartBanner';
import {boldify, stripMarkup} from '../../utils';
import {getFromStorage, setToStorage} from '../../storage';
import {SearchBox} from '../SearchBox';
import {ResultList} from '../ResultList';
import {IndexPagePreviews} from '../IndexPagePreviews';
import {TopStories} from '../TopStories';
import {PopularSearchTicker} from '../PopularSearchTicker';
import {MenuBar} from '../MenuBar';
import {BackToTop} from '../BackToTop';
import {OfflinePopUp} from '../OfflinePopUp';
import {AboutOverlay} from '../AboutOverlay';
import {SocialButtons} from '../SocialButtons';
import {AdSlot} from '../AdSlot';
import {StickyBillboard} from '../StickyBillboard';
import {SmartBanner} from '../SmartBanner';
import {DebugInfoSmartBanner} from '../DebugInfoSmartBanner';

import {IconNormalLayout} from './IconNormalLayout';
import {IconHomesGrid} from './IconHomesGrid';
import styles from './styles.css';

const pushHistory = (newQuery: string | null) => {
  if (history.pushState) {
    const parsed = new URL(window.location.href, true);
    const params = parsed.query;

    if (newQuery) {
      params.q = newQuery;
    } else {
      delete params.q;
    }

    parsed.set('query', params);
    window.history.pushState({path: parsed.href}, '', parsed.href);
  }
};

type Layout = 'normal' | 'homesgrid';

export interface DebugModeState {
  coverage: 'mol' | 'metro';
}

const SOURCE_GEO_COOKIE = 'X-GEO';
const GEO_STORAGE_KEY = 'geo';
const DEBUG_MODE_COMMANDS: {[key: string]: DebugModeState} = {
  'always brushing coffee': {coverage: 'mol'},
  'always brushing tea': {coverage: 'metro'}
};
const getGeoFromCookie = () => {
  try {
    const cookieValue = Cookies.get(SOURCE_GEO_COOKIE);

    if (cookieValue && (GEO_COUNTRIES as string[]).includes(cookieValue.toLowerCase())) {
      return cookieValue.toLowerCase() as GeoCountry;
    }
  } catch (error) {
    // Cookies disabled, etc, ignore
  }

  return 'gb';
};
const getGeoFromStorage = () => {
  const value = getFromStorage(GEO_STORAGE_KEY) as string;

  if ((GEO_COUNTRIES as string[]).includes(value)) {
    return value as GeoCountry;
  }

  return null;
};

type SearchSource =
  | 'autocomplete'
  | 'ticker'
  | 'manual'
  | 'queryParam'
  | 'history'
  | 'topStoriesMoreLikeThis'
  | 'suggestion';

interface AppProps {
  startingWithQuery?: boolean;
  showTabs?: boolean;
  homeRefreshInterval?: number | null;
  startTab?: TopStoriesCategory;
  overrideFirstStoryId?: string;
  hideFacebook?: boolean;
  userLocation?: LocationResponse;
  showStaticBannerTop?: boolean;
  skyScraperSize?: 'small' | 'big' | null;
  stickyBillboardSize?: 'small' | 'big' | null;
  canFitSkiesAndStickyBillboard?: boolean;
  canFitSkies?: boolean;
  showStickyBillboard?: boolean;
  firstStoryIsMostRead?: boolean;
  firstStoryIsFBIA?: boolean;
  mobileBreakpoint?: boolean;
  showSmartBanner?: boolean;
  startWithStoriesOpen?: boolean;
  debugInfoSmartBanner?: DebugInfoSmartBannerData | null;
  topStoriesLazyScroll?: boolean;
  abValue?: string | null;
  geoFromXP?: GeoCountry | null;
}

const AppBase = ({
  startingWithQuery = false,
  showTabs = true,
  homeRefreshInterval = null,
  startTab,
  overrideFirstStoryId,
  hideFacebook = false,
  showStaticBannerTop,
  skyScraperSize,
  stickyBillboardSize,
  canFitSkiesAndStickyBillboard,
  canFitSkies,
  showStickyBillboard,
  firstStoryIsMostRead,
  firstStoryIsFBIA,
  mobileBreakpoint,
  showSmartBanner,
  startWithStoriesOpen,
  debugInfoSmartBanner,
  topStoriesLazyScroll,
  abValue,
  geoFromXP
}: AppProps) => {
  const [query, setQuery] = useState<string | null>(null);
  const [layout, setLayout] = useState<Layout>('normal');
  const [results, setResults] = useState<Result[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [noMoreResults, setNoMoreResults] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [suggestion, setSuggestion] = useState<string | null>(null);
  const [betterSuggestion, setBetterSuggestion] = useState<string | null>(null);
  const [topStoriesPage, setTopStoriesPage] = useState(0);
  const [topStoriesTab, setTopStoriesTab] = useState<TopStoriesCategory>(startTab || 'news');
  const [debugMode, setDebugMode] = useState<DebugModeState | null>(null);
  const cookieGeo = getGeoFromCookie();
  const [aboutOpen, setAboutOpen] = useState(false);
  const [sourceGeo, setSourceGeoState] = useState<GeoCountry>(geoFromXP || getGeoFromStorage() || cookieGeo);
  const [appIsHidden, setAppIsHidden] = useState(false);
  const [refreshStamp, setRefreshStamp] = useState<number>(Date.now());
  const [tickerVisible, setTickerVisible] = useState(false);
  const [stickyBillboardVisible, setStickyBillboardVisible] = useState(false);
  const [userLocation, setUserLocation] = useState<LocationResponse | undefined>(undefined);
  const [smartBannerClosed, setSmartBannerClosed] = useState(false);

  const setSourceGeo = (newValue: GeoCountry) => {
    setSourceGeoState(newValue);
    setToStorage(GEO_STORAGE_KEY, newValue);
  };

  const handleSearch = async (newQuery: string | null, source: SearchSource, fromPop = false) => {
    if (newQuery && Object.keys(DEBUG_MODE_COMMANDS).includes(newQuery)) {
      setDebugMode(DEBUG_MODE_COMMANDS[newQuery]);

      return;
    }

    setQuery(newQuery);
    setResults(null);
    setBetterSuggestion(null);
    setSuggestion(null);
    setError(null);
    setNoMoreResults(false);
    clearShownList();
    setLayout('normal');

    if (!fromPop) {
      pushHistory(newQuery);
    }

    const currentPageType = newQuery ? 'search' : 'home';

    trackEvent(events.TAB_VIEW_EVENT, {
      channel: currentPageType,
      query: newQuery || undefined,
      source
    });

    if (!newQuery) {
      setLoading(false);
      setExternalAppState('home');

      return;
    }

    setLoading(true);
    try {
      const response = await search(newQuery);

      setSuggestion((response && response.suggestion) || null);
      setBetterSuggestion((response && response.betterSuggestion) || null);
      setNoMoreResults(Boolean(response && response.endOfResults));
      setResults(response && response.results);
      setExternalAppState('search', {query: newQuery});
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  const searchFromQuery = (fromPop = false) => {
    const parsedUrl = new URL(window.location.href, true);
    const params = parsedUrl.query;

    if (params && params.q) {
      let source: SearchSource = 'queryParam';

      if (params.trackingScope) {
        source = (params.trackingScope as SearchSource | void) || source;
        delete params.trackingScope;
        parsedUrl.set('query', params);

        window.history.replaceState({}, document.title, parsedUrl.href);
      }

      handleSearch(params.q, source, fromPop).catch(() => {});
    } else if (fromPop) {
      handleSearch(null, 'history', fromPop).catch(() => {});
    }
  };

  const requestMoreResults = async () => {
    if (loading || !results || !results.length || !query) {
      return;
    }

    setLoading(true);

    try {
      const lastCursor = results[results.length - 1].cursor;
      const existingIds = results.map((result) => result.id);
      const response = await search(query, {lastCursor});

      if (response && response.results) {
        const moreResults = response.results.filter((result) => !existingIds.includes(result.id));

        if (moreResults.length) {
          setResults(results.concat(moreResults));
        } else {
          setNoMoreResults(true);
        }
      }
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const popstateHandler = () => searchFromQuery(true);

    searchFromQuery();
    setToStorage('has-visited-before', true);

    window.addEventListener('popstate', popstateHandler);

    const checkVisibility = () => {
      setAppIsHidden(document.hidden || false);
    };

    document.addEventListener('visibilitychange', checkVisibility);

    return () => {
      window.removeEventListener('popstate', popstateHandler);
      document.removeEventListener('visibilitychange', checkVisibility);
    };
  }, []);

  useEffect(() => {
    if (appIsHidden || !homeRefreshInterval || query) {
      return;
    }

    const refreshInterval = setInterval(() => {
      setRefreshStamp((currentStamp) => {
        const now = Date.now();

        if (now - currentStamp > homeRefreshInterval) {
          return now;
        }

        return currentStamp;
      });
    }, 1000);

    return () => {
      clearInterval(refreshInterval);
    };
  }, [homeRefreshInterval, appIsHidden, query]);

  useEffect(() => {
    let abort = false;

    getUserLocation()
      .then((locationObj) => {
        if (abort) {
          return;
        }

        setUserLocation(locationObj);
      })
      .catch();

    return () => {
      abort = true;
    };
  }, []);

  useEffect(() => {
    setExternalAppGeo(sourceGeo);
    setExternalAppTab(topStoriesTab);
  }, [sourceGeo, topStoriesTab]);

  const handleChangeLayout = (newLayout: Layout) => {
    trackEvent(events.CHANGE_SEARCH_PAGE_LAYOUT, {layout: newLayout});
    setLayout(newLayout);
  };

  const handleTopStoriesMoreLikeThis = (topStory: Result) => {
    if (topStory.moreLikeTerms && topStory.moreLikeTerms.length) {
      const newQuery = topStory.moreLikeTerms.join(' ');

      window.open(`/?q=${encodeURIComponent(newQuery)}&trackingScope=topStoriesMoreLikeThis`, '_blank');
    }
  };

  const isFullScreenSearch = !results && !query && !startingWithQuery;
  const shouldShowSmartBanner = showSmartBanner && !smartBannerClosed && mobileBreakpoint;

  return (
    <div
      className={classnames(
        styles.appWrapper,
        tickerVisible && 'tickerVisible',
        stickyBillboardVisible && 'stickyBillboardVisible',
        shouldShowSmartBanner && styles.hasSmartBanner
      )}
    >
      {shouldShowSmartBanner && (
        <SmartBanner
          onClose={() => {
            setSmartBannerClosed(true);
          }}
        />
      )}
      {debugInfoSmartBanner && <DebugInfoSmartBanner debugInfo={debugInfoSmartBanner} />}
      {stickyBillboardSize && showStickyBillboard && (
        <StickyBillboard onVisibilityChange={setStickyBillboardVisible} size={stickyBillboardSize} />
      )}
      {!mobileBreakpoint && <SocialButtons sourceGeo={sourceGeo} mode="standalone" hideFacebook={hideFacebook} />}
      {!mobileBreakpoint && (
        <PopularSearchTicker
          highlightIcon={Boolean(debugMode)}
          onSearch={(searchTerm: string) => handleSearch(searchTerm, 'ticker')}
          sourceGeo={sourceGeo}
          onVisibilityChange={setTickerVisible}
        />
      )}
      {showStaticBannerTop && (
        <AdSlot position="sticky_banner_top" slotId="sticky_banner_top" slotType="staticMobileBanner" />
      )}
      <div
        className={classnames(
          styles.app,
          isFullScreenSearch && styles.fullScreenSearch,
          layout === 'homesgrid' && styles.homesGridLayout
        )}
      >
        <MenuBar
          isHome={isFullScreenSearch}
          onSelectGeo={setSourceGeo}
          onAbout={() => setAboutOpen(true)}
          selectedGeo={sourceGeo}
          firstGeo={cookieGeo}
          hideFacebook={hideFacebook}
        />
        <SearchBox
          fullScreen={isFullScreenSearch}
          loading={loading}
          onSearch={(searchTerm: string, isAutoComplete: boolean) =>
            handleSearch(searchTerm, isAutoComplete ? 'autocomplete' : 'manual')
          }
          query={query}
        />
        <AboutOverlay open={aboutOpen} onClose={() => setAboutOpen(false)} />
        {error && <div className={styles.error}>Error: {error.message}</div>}
        {betterSuggestion && (
          <div className={styles.suggestionContainer}>
            Did you mean{' '}
            <a
              className={styles.suggestionLink}
              onClick={() => handleSearch(stripMarkup(betterSuggestion), 'suggestion')}
            >
              {boldify(betterSuggestion)}
            </a>{' '}
            ?
          </div>
        )}
        {!betterSuggestion && suggestion && (
          <div className={styles.suggestionContainer}>
            Showing results for: <span className={styles.suggestionText}>{boldify(suggestion)}</span>
          </div>
        )}
        {debugMode && <div className={styles.debugInfo}>AB value: {abValue}</div>}
        {isFullScreenSearch && (
          <div className={styles.topStoriesWrapper}>
            <IndexPagePreviews
              loose={true}
              refreshStamp={refreshStamp}
              sourceGeo={sourceGeo}
              topStoriesPage={topStoriesPage}
              topStoriesTab={topStoriesTab}
            >
              <TopStories
                debug={debugMode}
                onPageChange={setTopStoriesPage}
                onTabChange={setTopStoriesTab}
                onMoreLikeThis={handleTopStoriesMoreLikeThis}
                refreshStamp={refreshStamp}
                sourceGeo={sourceGeo}
                showTabs={showTabs}
                startTab={startTab}
                overrideFirstStoryId={overrideFirstStoryId}
                hideFacebook={hideFacebook}
                firstStoryIsMostRead={firstStoryIsMostRead}
                firstStoryIsFBIA={firstStoryIsFBIA}
                userLocation={userLocation}
                startWithStoriesOpen={startWithStoriesOpen}
                topStoriesLazyScroll={topStoriesLazyScroll}
              />
            </IndexPagePreviews>
          </div>
        )}
        <div className={styles.resultWrapper}>
          {layout === 'normal' && (
            <ResultList
              debugMode={Boolean(debugMode)}
              noMoreResults={noMoreResults}
              requestMoreResults={requestMoreResults}
              results={results}
              query={query}
            />
          )}
          {results && query && (
            <div className={styles.homePreviewsWrapper}>
              <IndexPagePreviews
                query={query}
                noMoreResults={noMoreResults}
                resultsLoading={loading}
                gridLayout={layout === 'homesgrid'}
                sourceGeo={sourceGeo}
              />
            </div>
          )}
          {results && query && (
            <div className={styles.layoutButtons}>
              <button
                aria-label="default layout"
                className={classnames(layout === 'normal' && styles.activeLayout)}
                onClick={() => handleChangeLayout('normal')}
              >
                <IconNormalLayout />
              </button>
              <button
                aria-label="homepages grid layout"
                className={classnames(layout === 'homesgrid' && styles.activeLayout)}
                onClick={() => handleChangeLayout('homesgrid')}
              >
                <IconHomesGrid />
              </button>
            </div>
          )}
        </div>
        <div className={styles.bottomSticky}>
          <BackToTop isHome={isFullScreenSearch} />
        </div>
      </div>
      <OfflinePopUp />
      {skyScraperSize && canFitSkies && (!stickyBillboardVisible || canFitSkiesAndStickyBillboard) && (
        <AdSlot position="sky_left_top" slotId="sky_left_top" slotType="skyScraperLeft" size={skyScraperSize} />
      )}
      {skyScraperSize && canFitSkies && (!stickyBillboardVisible || canFitSkiesAndStickyBillboard) && (
        <AdSlot position="sky_right_top" slotId="sky_right_top" slotType="skyScraperRight" size={skyScraperSize} />
      )}
      <AdSlot position="out_of_page" slotId="out_of_page" slotType="richMedia" />
    </div>
  );
};

const mapSizesToProps = ({
  width,
  height
}: {
  width: number;
  height: number;
}): {
  canFitSkies: boolean;
  canFitSkiesAndStickyBillboard: boolean;
  mobileBreakpoint: boolean;
  showStaticBannerTop: boolean;
  stickyBillboardSize: 'small' | 'big' | null;
  skyScraperSize: 'small' | 'big' | null;
} => {
  let showStaticBannerTop = false;
  let skyScraperSize: 'small' | 'big' | null = null;
  let stickyBillboardSize: 'small' | 'big' | null = null;

  if (width >= 320 && width < 768) {
    showStaticBannerTop = true;
  }

  if (width >= 768) {
    stickyBillboardSize = 'small';
  }
  if (width >= 1264) {
    stickyBillboardSize = 'big';
    skyScraperSize = 'small';
  }
  if (width >= 2000) {
    skyScraperSize = 'big';
  }

  const canFitSkiesAndStickyBillboard = height >= 700 + (stickyBillboardSize === 'big' ? 250 : 90);
  const canFitSkies = height >= 670;
  const mobileBreakpoint = width < 768;

  return {
    canFitSkies,
    canFitSkiesAndStickyBillboard,
    mobileBreakpoint,
    showStaticBannerTop,
    skyScraperSize,
    stickyBillboardSize
  };
};

export const App = withSizes<AppProps, AppProps>(mapSizesToProps)(AppBase);
