import React, {useEffect, useRef} from 'react';
import classnames from 'classnames';

import {mountAd, unmountAd, updateAd} from '../../api/ads';

import styles from './styles.css';

interface AdSlotProps {
  position: string;
  slotId: string;
  slotType:
    | 'mpu'
    | 'billboard'
    | 'staticMobileBanner'
    | 'skyScraperLeft'
    | 'skyScraperRight'
    | 'stickyBillboard'
    | 'richMedia';
  size?: 'big' | 'small';
}

export const AdSlot = ({position, slotId, slotType, size}: AdSlotProps) => {
  const sizeRef = useRef<'big' | 'small' | undefined>(size);

  useEffect(() => {
    mountAd(slotId, position);

    return () => {
      unmountAd(slotId, position);
    };
  }, []);

  useEffect(() => {
    if (sizeRef.current !== size) {
      updateAd(slotId, position);
    }

    sizeRef.current = size;
  }, [size]);

  return (
    <div className={classnames(styles[slotType], size && styles[size])}>
      <div className={styles.inner} id={slotId}></div>
    </div>
  );
};
