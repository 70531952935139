import React from 'react';
import classNames from 'classnames';

import styles from './styles.css';

interface Props {
  fixFont?: boolean
}

export const HeaderLogo = ({fixFont}: Props) => {
  return (
    <div className={styles.holder}>
      <div className={classNames(styles.container, fixFont && styles.fixFont)}>
        <div>Newzit</div>
      </div>
    </div>
  )
};
