export const buildRTATrackMacro = async () => {
  try {
    /* tslint:disable-next-line:no-any */
    const RTA = (window as any).RTA;

    if (RTA) {
      const hosts = RTA.hosts() as string[];
      const ruids = await RTA.ruids;

      return hosts.map((host) => [
        `${window.location.protocol}//${host}/s/p`,
        {
          body: JSON.stringify({
            ids: ruids[host]
          }),
          credentials: 'include',
          headers: {
            'content-type': 'application/json'
          },
          method: 'POST',
          mode: 'cors'
        }
      ]);
    }
  } catch (error) {
    /* tslint:disable:no-console */
    console.error('### Error building RTA macros', error);
  }

  return [];
};
