// Base events
export const PAGE_VIEW_EVENT = 'exview';
export const TAB_VIEW_EVENT = 'cview';

// Ticker
export const TICKER_SELECT = 'ticker_select_term';
export const TICKER_SPEED = 'ticker_speed_change';
export const TICKER_PAUSE = 'ticker_pause';
export const TICKER_PLAY = 'ticker_play';

// Top stories
export const TOP_STORIES_PAGINATION_MORE = 'topstories_pagination_more';
export const TOP_STORIES_PAGINATION_LESS = 'topstories_pagination_less';
export const TOP_STORIES_RELATED_OPEN = 'topstories_related_open';
export const TOP_STORIES_RELATED_CLOSE = 'topstories_related_close';
export const TOP_STORIES_AUTOREFRESH = 'topstories_autorefresh';

// Search results
export const CHANGE_SEARCH_PAGE_LAYOUT = 'change_search_page_layout';

// Flags
export const FLAG_SELECT = 'flag_select';

// Back to top
export const BACK_TO_TOP = 'back_to_top_click';
export const BACK_TO_HOME = 'back_to_home_click';

// Social
export const SOCIAL_FOLLOW_FACEBOOK = 'social_follow_facebook';
export const SOCIAL_FOLLOW_TWITTER = 'social_follow_twitter';
export const SOCIAL_SHARE_FACEBOOK = 'social_share_facebook';
export const SOCIAL_SHARE_TWITTER = 'social_share_twitter';
export const SOCIAL_SHARE_TOPSTORY_FACEBOOK = 'social_share_topstory_facebook';
export const SOCIAL_SHARE_TOPSTORY_FACEBOOK_OVERRIDE = 'social_share_topstory_facebook_group';

// Smart Banner
export const SMART_BANNER_IMPRESSION = 'smart_banner_impression';
export const SMART_BANNER_CLICK = 'smart_banner_click';
export const SMART_BANNER_CLOSE = 'smart_banner_close';
