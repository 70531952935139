import {AppState} from './externalAppState';

interface AppStateMessage {
  data: {
    geo?: string;
    query?: string;
    state?: string;
    tab?: string;
  };
  scope: 'appstate';
}

interface UserActionMessage {
  data: {
    eventName: string;
  };
  scope: 'userAction';
}

interface AdSlotMessage {
  data: {
    appState: AppState;
    position: string;
    size?: string;
    slotId: string;
  };
  scope: 'adSlotMount' | 'adSlotUnmount' | 'adSlotUpdate';
}

export interface AdQueueWindow extends Window {
  adverts?: {queue: Array<AppStateMessage | UserActionMessage | AdSlotMessage>};
}

export const pushToAdsQueue = (message: AppStateMessage | UserActionMessage | AdSlotMessage) => {
  const win = window as AdQueueWindow;

  if (!win.adverts) {
    win.adverts = {queue: []};
  } else if (!win.adverts.queue) {
    win.adverts.queue = [];
  }

  win.adverts.queue.push(message);
};
