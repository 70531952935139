import React from 'react';

export const IconHomesGrid = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31">
    <path
      fill="#D8D8D8"
      fillRule="evenodd"
      d="M12 .812v12.376a.812.812 0 0 1-.812.812H.812A.812.812 0 0 1 0 13.188V.58C0 .26.26 0 .58 0h10.84c.32 0 .58.26.58.58v.232zM10 4H2v7.188c0 .448.364.812.812.812h6.376a.812.812 0 0 0 .812-.812V4zm2 13.812v12.376a.812.812 0 0 1-.812.812H.812A.812.812 0 0 1 0 30.188V17.58c0-.32.26-.58.58-.58h10.84c.32 0 .58.26.58.58v.232zM10 21H2v7.188c0 .448.364.812.812.812h6.376a.812.812 0 0 0 .812-.812V21zM27 .812v12.376a.812.812 0 0 1-.812.812H15.812a.812.812 0 0 1-.812-.812V.58c0-.32.26-.58.58-.58h10.84c.32 0 .58.26.58.58v.232zM25 4h-8v7.188c0 .448.364.812.812.812h6.376a.812.812 0 0 0 .812-.812V4zm2 13.812v12.376a.812.812 0 0 1-.812.812H15.812a.812.812 0 0 1-.812-.812V17.58c0-.32.26-.58.58-.58h10.84c.32 0 .58.26.58.58v.232zM25 21h-8v7.188c0 .448.364.812.812.812h6.376a.812.812 0 0 0 .812-.812V21z"
    />
  </svg>
);
