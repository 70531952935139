// tslint:disable: no-any
export const toLowerCase = (obj: any) => {
  if (typeof obj !== 'object' || obj === null || Array.isArray(obj)) {
    if (typeof obj === 'string') {
      return obj.toLowerCase();
    }

    return obj;
  }

  const newObj: any = {};

  Object.keys(obj).forEach((key) => {
    newObj[key] = toLowerCase(obj[key]);
  });

  return newObj;
};

export const trimAllSpaces = (str: string) => str.replace(/\s+/g, '');

export const deleteFalsyValues = (obj: any) => {
  for (const key of Object.keys(obj)) {
    if (!obj[key]) {
      // tslint:disable-next-line: no-dynamic-delete
      delete obj[key];
    }
  }

  return obj;
};

export const getHostname = (href: string) => {
  try {
    if (typeof href === 'string') {
      const hrefUrl = new URL(href);

      return hrefUrl.hostname;
    }
  } catch (error) {
    // Ignore
  }

  return undefined;
};
