import {loadScript} from '../utils';
import {publishExternalEvent} from '../api/externalAppState';
import {getPlatform} from '../api/platform';

import {sendEventToGoogleAnalytics} from './googleAnalytics';
import * as events from './events';
import {trackIpsos} from './ipsos';

export {events};

const SCRIPT_ELEMENT_CLASS = 'RTA2-loader';

const DIRECT_EVENTS = [events.PAGE_VIEW_EVENT, events.TAB_VIEW_EVENT];
const GENERIC_EVENT_NAME = 'page_event';

export interface Metadata {
  [key: string]: string | string[] | number | undefined;
}

interface RTAIds {
  user: string;
  created: number;
  page: string;
  visit: string;
}

interface RTAWindow extends Window {
  // tslint:disable no-any
  RTA?: {
    tedEvent: (
      eventName: string,
      metadata?: {action: string; name: string; value: Metadata | string} | Metadata
    ) => void;
    ids: Promise<RTAIds>;
  };
  appEventData?: any[];
  molABTest?: any;
  // tslint:enable no-any
}

interface QueuedEvent {
  eventName: string;
  metadata?: Metadata;
}

let eventQueue: QueuedEvent[] = [];

// For page events sent before any cview has
let rtaPageEventQueue: QueuedEvent[] = [];
let rtaCviewHasBeenSent = false;

export const clearEventQueue = () => {
  eventQueue = [];
};

export const clearRtaPageEventQueue = () => {
  rtaPageEventQueue = [];
  rtaCviewHasBeenSent = false;
};

const buildInternalReferrer = (key: string) =>
  [window.location.protocol, '/' + window.location.host, 'click', key].join('/');

const sendEventToRTA = (eventName: string, metadata?: Metadata) => {
  const RTA = (window as RTAWindow).RTA;

  if (!RTA || !RTA.tedEvent) {
    throw new Error('RTA not loaded');
  }

  if (DIRECT_EVENTS.includes(eventName)) {
    if (eventName === events.PAGE_VIEW_EVENT) {
      if (metadata) {
        metadata.referrer = buildInternalReferrer((metadata.topStoriesKey as string) || 'search');

        if (metadata.query && typeof metadata.query === 'string') {
          metadata.referrer += '?q=' + encodeURIComponent(metadata.query);
          metadata.tags = metadata.query.trim().split(/\s+/);
        }

        if (metadata.subchannel && typeof metadata.subchannel === 'string') {
          publishExternalEvent(metadata.subchannel);
        }
      } else {
        // tslint:disable-next-line: no-console
        console.warn('Cannot track click to unknown destination');

        return;
      }
    } else if (eventName === events.TAB_VIEW_EVENT) {
      if (metadata && metadata.previousTabKey && metadata.previousTabKey !== metadata.channel) {
        metadata.referrer = buildInternalReferrer(metadata.previousTabKey as string);
      }
      if (metadata && metadata.query && typeof metadata.query === 'string') {
        metadata.tags = metadata.query.trim().split(/\s+/);
      }
    }

    if (metadata) {
      metadata.rp = getPlatform();
    }

    RTA.tedEvent(eventName, metadata);

    if (eventName === events.TAB_VIEW_EVENT) {
      rtaCviewHasBeenSent = true;

      for (const queuedEvent of rtaPageEventQueue) {
        sendEventToRTA(queuedEvent.eventName, queuedEvent.metadata);
      }
      rtaPageEventQueue = [];
    }
  } else if (eventName === 'push') {
    RTA.tedEvent('push', metadata);
  } else {
    if (!rtaCviewHasBeenSent) {
      rtaPageEventQueue.push({eventName, metadata});

      return;
    }

    RTA.tedEvent(GENERIC_EVENT_NAME, {
      action: 'store',
      name: eventName,
      value: metadata || 'event'
    });

    publishExternalEvent(eventName);
  }
};

const sendEventToIpsos = (eventName: string, metadata?: Metadata) => {
  if (eventName === events.TAB_VIEW_EVENT) {
    const section = metadata && metadata.query ? 'newzit' : 'home';

    trackIpsos(section);
  }
};

export const trackEvent = (eventName: string, metadata?: Metadata) => {
  const RTA = (window as RTAWindow).RTA;

  if (RTA && RTA.tedEvent) {
    sendEventToRTA(eventName, metadata);
  } else {
    eventQueue.push({eventName, metadata});
  }

  sendEventToGoogleAnalytics(eventName, metadata);
  sendEventToIpsos(eventName, metadata);
};

export const initTracking = async ({
  abExperiments,
  hosts,
  script
}: {
  abExperiments: string[] | null;
  hosts: string;
  script: string;
}) => {
  try {
    if (abExperiments) {
      (window as RTAWindow).molABTest = abExperiments;
    }

    await loadScript(script, {
      class: SCRIPT_ELEMENT_CLASS,
      'data-hosts': hosts
    });

    const RTA = (window as RTAWindow).RTA;

    if (!RTA || !RTA.tedEvent) {
      throw new Error('Error loading RTA');
    }

    for (const queuedEvent of eventQueue) {
      sendEventToRTA(queuedEvent.eventName, queuedEvent.metadata);
    }

    clearEventQueue();
  } catch (error) {
    // TODO: log error
  }
};
