// tslint:disable: object-literal-sort-keys
export const UA_ID = 'UA-3639451-14';
export const GA4_ID = 'G-F0PV4JP12Y';

export const CUSTOM_DIMENSION_MAP = {
  dimension1: 'renderPlatform',
  dimension6: 'channel',
  dimension17: 'gdprStatus',
  dimension21: 'userAgent',
  dimension22: 'pageUrl',
  dimension23: 'internalSearchTerms',
  dimension24: 'referrer',
  dimension36: 'userLocalServerDate',
  dimension38: 'clientId',
  dimension40: 'itoExternalHit',
  dimension41: 'itoExternalSession',
  dimension42: 'autoRefreshStatus',
  dimension46: 'referringUrl',
  dimension48: 'webPushCampaignId',
  dimension49: 'webPushStatus',
  dimension63: 'articleFindingMethod',
  dimension64: 'brandDomain',
  dimension65: 'destinationUrl',
  dimension66: 'parentArticleTitle',
  dimension98: 'referringDomain',
  metric1: 'internalSearchIncrease',
  metric3: 'autoRefreshIncrease',
  metric11: 'socialShareClicksIncrease'
};
