import React, {useState, useEffect, ReactChild, useRef} from 'react';
import TimeAgo from 'react-timeago';
import classnames from 'classnames';

import {Result, getSiteUrlFromItemUrl} from '../../api/search';
import {shareFacebook} from '../../api/social';
import {boldify} from '../../utils';

import styles from './styles.css';

const nativeLazyLoadSupported = Boolean('loading' in HTMLImageElement.prototype);

interface ResultItemProps {
  breaking?: boolean;
  onClick?: () => void;
  onSiteClick?: () => void;
  onShareFacebook?: () => void;
  result: Result;
  showRelated?: boolean;
  type?: 'searchResult' | 'topStory';
  buttonNode?: ReactChild;
  isSmall?: boolean;
  isMainInModal?: boolean;
  isGroupWinner?: boolean;
  showFacebookShare?: boolean;
  mostRead?: boolean;
  lazyLoadImage?: boolean;
  fbia?: boolean;
  headlineOnTop?: boolean;
}

export const ResultItem = ({
  onClick,
  onSiteClick,
  result,
  type = 'searchResult',
  breaking = false,
  showRelated = false,
  buttonNode,
  isSmall,
  isMainInModal = false,
  isGroupWinner,
  showFacebookShare,
  onShareFacebook,
  mostRead,
  lazyLoadImage,
  fbia,
  headlineOnTop
}: ResultItemProps) => {
  const {
    clickUrl,
    url,
    title,
    resultThumbUrl,
    related,
    timestamp,
    siteIconUrl,
    siteName,
    paywall,
    id,
    sponsoredLabel
  } = result;
  const siteUrl = getSiteUrlFromItemUrl(url);
  const [imageError, setImageError] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);
  const [lazyLoadPolyfillActivated, setLazyLoadPolyfillActivated] = useState(false);

  useEffect(() => {
    setImageError(false);
  }, [result]);

  useEffect(() => {
    if (!lazyLoadImage || nativeLazyLoadSupported || !resultThumbUrl || !imageRef.current) {
      return;
    }

    const handleIntersect = (entries: IntersectionObserverEntry[], currentObserver: IntersectionObserver) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          setLazyLoadPolyfillActivated(true);
          currentObserver.disconnect();
        }
      }
    };
    const observer = new IntersectionObserver(handleIntersect, {
      rootMargin: '700px'
    });

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [lazyLoadImage, resultThumbUrl]);

  const handleImageError = () => {
    requestAnimationFrame(() => setImageError(true));
  };

  const shareTopStoryOnFacebook = () => {
    shareFacebook(id, 'facebook_share_topstory');

    if (onShareFacebook) {
      onShareFacebook();
    }
  };

  let lazyLoadAttributes = {};
  let siteIconLazyLoadAttributes = {};

  if (lazyLoadImage && !lazyLoadPolyfillActivated) {
    if (resultThumbUrl) {
      lazyLoadAttributes = nativeLazyLoadSupported ? {loading: 'lazy'} : {'data-src': resultThumbUrl};
    }
    if (siteIconUrl) {
      siteIconLazyLoadAttributes = nativeLazyLoadSupported ? {loading: 'lazy'} : {'data-src': siteIconUrl};
    }
  }

  const showOriginalImageSrc = lazyLoadPolyfillActivated || nativeLazyLoadSupported || !lazyLoadImage;

  const headlineComponent = (
    <h2 className={styles.title}>
      <a onClick={onClick} rel="noopener" href={clickUrl} target="_blank">
        {breaking && headlineOnTop && <div className={styles.breakingLabel}>BREAKING</div>}
        {mostRead && headlineOnTop && <div className={styles.mostReadLabel}>TRENDING</div>}
        {sponsoredLabel && headlineOnTop && <div className={styles.sponsoredLabel}>{sponsoredLabel}</div>}
        {fbia && headlineOnTop && <div className={styles.fbiaLabel}>TRENDING ON FACEBOOK</div>}
        {boldify(title)}
      </a>
    </h2>
  );

  return (
    <div
      className={classnames(
        styles.result,
        styles[type],
        breaking && styles.breaking,
        showRelated && related && styles.related,
        isSmall && styles.isSmall,
        isMainInModal && styles.isMainInModal,
        isGroupWinner && styles.isGroupWinner,
        mostRead && styles.mostRead,
        sponsoredLabel && styles.sponsored,
        fbia && styles.fbia,
        headlineOnTop && styles.headlineOnTop
      )}
    >
      {headlineOnTop && headlineComponent}
      {resultThumbUrl && (
        <div
          className={classnames(styles.thumbContainer, imageError && styles.imageError)}
          style={imageError ? {backgroundImage: `url(${siteIconUrl ? siteIconUrl : '/favicon.ico'})`} : {}}
        >
          <a onClick={onClick} rel="noopener" href={clickUrl} target="_blank">
            <img
              alt="thumbnail"
              key={resultThumbUrl}
              onError={handleImageError}
              ref={imageRef}
              src={showOriginalImageSrc ? resultThumbUrl : undefined}
              {...lazyLoadAttributes}
            />
            {paywall && <div className={styles.paywallFlag}>PAYWALL</div>}
            {breaking && !headlineOnTop && <div className={styles.breakingLabel}>BREAKING</div>}
            {mostRead && !headlineOnTop && <div className={styles.mostReadLabel}>TRENDING</div>}
            {sponsoredLabel && !headlineOnTop && <div className={styles.sponsoredLabel}>{sponsoredLabel}</div>}
            {fbia && !headlineOnTop && <div className={styles.fbiaLabel}>TRENDING ON FACEBOOK</div>}
          </a>
        </div>
      )}
      <div className={styles.info}>
        {!headlineOnTop && headlineComponent}
        {type !== 'topStory' && (
          <a onClick={onClick} className={styles.url} rel="noopener" href={clickUrl} target="_blank">
            {url}
          </a>
        )}
        <div className={styles.snippet}>
          {breaking && !headlineOnTop && (
            <a className={styles.breakingLabel} onClick={onClick} rel="noopener" href={clickUrl} target="_blank">
              BREAKING
            </a>
          )}
          {mostRead && !headlineOnTop && (
            <a className={styles.mostReadLabel} onClick={onClick} rel="noopener" href={clickUrl} target="_blank">
              TRENDING
            </a>
          )}
          {sponsoredLabel && !headlineOnTop && (
            <a className={styles.sponsoredLabel} onClick={onClick} rel="noopener" href={clickUrl} target="_blank">
              {sponsoredLabel}
            </a>
          )}
          {fbia && !headlineOnTop && (
            <a className={styles.fbiaLabel} onClick={onClick} rel="noopener" href={clickUrl} target="_blank">
              TRENDING ON FACEBOOK
            </a>
          )}
          {siteName && (
            <a className={styles.siteLink} href={siteUrl} onClick={onSiteClick} target="_blank" rel="noopener nofollow">
              {siteIconUrl && (
                <img
                  alt="site icon"
                  className={styles.favicon}
                  src={showOriginalImageSrc ? siteIconUrl : undefined}
                  {...siteIconLazyLoadAttributes}
                />
              )}
              <div className={styles.content}>
                <span>{siteName}</span>
                {timestamp && <span className={styles.snippetSeparator}>·</span>}
                {timestamp && <TimeAgo date={timestamp} />}
              </div>
            </a>
          )}
          {buttonNode}
          {showFacebookShare && (
            <button className={styles.facebookShare} onClick={shareTopStoryOnFacebook} aria-label="Share on Facebook" />
          )}
        </div>
      </div>
    </div>
  );
};
