import React, {useState, KeyboardEvent, ChangeEvent, useEffect, useRef} from 'react';
import classnames from 'classnames';

import {autocomplete} from '../../api/search';
import {HeaderLogo} from '../HeaderLogo';

import styles from './styles.css';
import {IconSearch} from './IconSearch';

interface SearchBoxProps {
  fullScreen: boolean;
  loading: boolean;
  onSearch: (query: string, isAutoComplete: boolean) => Promise<void>;
  query: string | null;
}

export const SearchBox = ({fullScreen, loading, onSearch, query}: SearchBoxProps) => {
  const [inputValue, setInputValue] = useState(query);
  const [autocompleteEntries, setAutocompleteEntries] = useState<string[] | null>(null);
  const [selectedAutocompleteIndex, setSelectedAutocompleteIndex] = useState<number | null>(null);
  const [inputFocused, setInputFocused] = useState(false);
  const [autocompleteUndoValue, setAutocompleteUndoValue] = useState<string | null>(null);
  const [autocompleteTerm, setAutocompleteTerm] = useState<string | null>(null);
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const lastSelectedAutocompleteIndex = useRef(selectedAutocompleteIndex);

  const handleSearch = (newValue?: string) => {
    const searchString = newValue || inputValue;
    const isAutoComplete = Boolean(newValue);
    if (!loading && searchString) {
      setSelectedAutocompleteIndex(null);
      setAutocompleteEntries(null);
      onSearch(searchString.trim(), isAutoComplete).catch();
    }
  };
  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value && event.currentTarget.value.trim() ? event.currentTarget.value : '';

    setSelectedAutocompleteIndex(null);
    setAutocompleteEntries(null);
    setAutocompleteUndoValue(newValue);
    setInputValue(newValue || null);
    setInputFocused(true);

    if (newValue) {
      setAutocompleteTerm(newValue);
    }
  };
  const handleAutocompleteClick = (value: string) => {
    setSelectedAutocompleteIndex(null);
    setAutocompleteEntries(null);
    setInputValue(value);
    handleSearch(value);
  };
  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && selectedAutocompleteIndex === null) {
      handleSearch();
    }
    if (event.key === 'Escape') {
      setInputFocused(false);
    }
    if (autocompleteEntries && autocompleteEntries.length) {
      if (event.key === 'Enter' && selectedAutocompleteIndex !== null) {
        const entry = autocompleteEntries[selectedAutocompleteIndex];
        setAutocompleteUndoValue(null);
        handleAutocompleteClick(entry);
      }
      if (event.key === 'ArrowUp' && selectedAutocompleteIndex !== null) {
        if (selectedAutocompleteIndex <= 0) {
          setSelectedAutocompleteIndex(null);
        } else {
          setSelectedAutocompleteIndex(selectedAutocompleteIndex - 1);
        }
      }
      if (
        event.key === 'ArrowDown' &&
        (selectedAutocompleteIndex === null || selectedAutocompleteIndex < autocompleteEntries.length - 1)
      ) {
        if (selectedAutocompleteIndex === null) {
          setSelectedAutocompleteIndex(0);
        } else {
          setSelectedAutocompleteIndex(selectedAutocompleteIndex + 1);
        }
      }
    }
  };

  useEffect(() => {
    if (query !== inputValue) {
      setInputValue(query);
    }
  }, [query]);

  useEffect(() => {
    if (
      selectedAutocompleteIndex === null &&
      autocompleteUndoValue &&
      lastSelectedAutocompleteIndex.current !== selectedAutocompleteIndex
    ) {
      setInputValue(autocompleteUndoValue);
    } else if (selectedAutocompleteIndex !== null && autocompleteEntries) {
      setInputValue(autocompleteEntries[selectedAutocompleteIndex]);
    }
    lastSelectedAutocompleteIndex.current = selectedAutocompleteIndex;
  }, [selectedAutocompleteIndex]);

  useEffect(() => {
    if (!inputFocused) {
      setSelectedAutocompleteIndex(null);
    }
  }, [inputFocused]);

  useEffect(() => {
    let aborted = false;

    if (autocompleteTerm) {
      (async () => {
        try {
          const entries = await autocomplete(autocompleteTerm);

          if (!aborted) {
            setAutocompleteEntries(entries);
          }
        } catch (error) {
          setAutocompleteEntries(null);
        }
      })().catch();
    } else {
      setSelectedAutocompleteIndex(null);
      setAutocompleteEntries(null);
    }

    return () => {
      aborted = true;
    };
  }, [autocompleteTerm]);

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      const wrapperElement = inputWrapperRef.current;

      if (wrapperElement && !wrapperElement.contains(event.target as HTMLElement) && wrapperElement !== event.target) {
        setInputFocused(false);
      }
    };

    document.addEventListener('click', handler);

    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  const showAutocomplete = autocompleteEntries && autocompleteEntries.length && inputFocused;
  // tslint:disable-next-line: no-unnecessary-callback-wrapper
  const buttonClickHandler = () => handleSearch();

  return (
    <div className={classnames(fullScreen ? styles.fullScreenContainer : styles.topContainer)}>
      <div className={styles.searchControl}>
        <a href="/" className={classnames(styles.logo, !fullScreen && styles.logoFixed)}>
          <HeaderLogo fixFont={!fullScreen} />
        </a>
        <div
          className={classnames(
            styles.inputWrapper,
            inputValue && styles.hasQuery,
            showAutocomplete && styles.autocompleteOpen
          )}
          ref={inputWrapperRef}
        >
          <input
            aria-label="Search Newzit"
            autoComplete="off"
            disabled={loading}
            className={styles.input}
            onChange={handleChange}
            onFocus={() => setInputFocused(true)}
            onKeyDown={handleKeyPress}
            placeholder="Search Newzit"
            value={inputValue || ''}
          />
          <button
            aria-label="search"
            disabled={loading || !inputValue}
            className={styles.searchButton}
            onClick={buttonClickHandler}
          >
            <IconSearch />
          </button>
          {showAutocomplete && (
            <div className={styles.autocompleteList}>
              {autocompleteEntries &&
                autocompleteEntries.map((entry, idx) => (
                  <div
                    className={classnames(
                      styles.autocompleteItem,
                      idx === selectedAutocompleteIndex && styles.autocompleteSelected
                    )}
                    key={entry}
                    onClick={() => handleAutocompleteClick(entry)}
                  >
                    {entry}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
