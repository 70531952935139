import React from 'react';

import {DebugInfoSmartBannerData} from '../../api/smartBanner';

import styles from './styles.css';

const getTimeUnits = (secondsRaw: number) => {
  const days = Math.floor(secondsRaw / 86400);
  const hours = Math.floor((secondsRaw % 86400) / 3600);
  const minutes = Math.floor((secondsRaw % 3600) / 60);
  const seconds = secondsRaw % 60;

  return {days, hours, minutes, seconds};
};

const humanTime = (time: number) => {
  const {days, hours, minutes, seconds} = getTimeUnits(Math.floor(time / 1000));

  return `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;
};

export const DebugInfoSmartBanner = ({debugInfo}: {debugInfo: DebugInfoSmartBannerData}) => {
  const {isAndroid, isIOS, isToShow, lastClosed, now, remaining} = debugInfo;

  return (
    <div className={styles.container}>
      <div className={styles.heading}>SMART BANNER DEBUG INFO</div>
      <div className={styles.infoItem}>
        DEVICE: {(isAndroid && 'ANDROID') || (isIOS && 'IPHONE') || 'NEITHER ANDROID NOR IPHONE'}
      </div>
      <div className={styles.infoItem}>LAST CLOSED: {lastClosed ? `${humanTime(now - lastClosed)} ago` : 'NEVER'}</div>
      <div className={styles.infoItem}>
        LAST CLOSED DATE (UTC): {lastClosed ? new Date(lastClosed).toISOString() : 'NEVER'}
      </div>
      <div className={styles.infoItem}>
        TIME REMAINING TO SHOW AGAIN: {remaining ? (remaining < 0 ? 'PAST' : humanTime(remaining)) : 'N/A'}
      </div>
      <div className={styles.infoItem}>SHOULD BE SHOWN THIS PAGELOAD: {isToShow ? 'YES' : 'NO'}</div>
    </div>
  );
};
