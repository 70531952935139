import React, {useState, useEffect, useRef, ReactElement} from 'react';
import classnames from 'classnames';
import withSizes from 'react-sizes';
import URL from 'url-parse';

import {trackEvent, events} from '../../tracking';
import {getCategoryKeyByTabAndGeo} from '../../api';
import {ApiResult, getFreshDomainThumbnailUrls, clearShownList} from '../../api/domainThumbnails';
import {AdSlot} from '../AdSlot';

import styles from './styles.css';
import {IndexPagePreviewsProps} from './types';
import {mapSizesToProps} from './utils';
import {IndexPagePreview} from './IndexPagePreview';

const SHOW_ALL_PAGES = true;
const THUMBS_PER_PAGE = 4;
const THUMBS_PER_PAGE_SPLASH = 12;
const LOOSE_FIRST_STEP = SHOW_ALL_PAGES ? 12 : 10;
const LOOSE_FIRST_STEP_THREE_COLUMN = SHOW_ALL_PAGES ? 9 : 8;
const BILLBOARD_COUNT_MAX = 2;
const BILLBOARD_STEP = 12;

export const jumpToPreviews = () => {
  const element = document.body.querySelector('[data-live-homepages]');

  if (element) {
    element.scrollIntoView();
  }
};

const IndexPagePreviewsBase = ({
  children,
  query,
  noMoreResults,
  resultsLoading,
  hideHeading,
  maxItems,
  gridLayout,
  loose,
  topStoriesPage,
  topStoriesTab,
  layoutBreakpoint,
  sourceGeo = 'gb',
  refreshStamp = null
}: IndexPagePreviewsProps) => {
  let thumbsPerPage = maxItems || (query ? THUMBS_PER_PAGE : THUMBS_PER_PAGE_SPLASH);
  const [thumbnailUrls, setThumbnailUrls] = useState<ApiResult[]>([]);
  const moreButtonRef = useRef<HTMLDivElement>(null);
  const [isInViewport, setIsInViewport] = useState(false);
  const [waitingForMoreResults, setWaitingForMoreResults] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const topStoriesKey = topStoriesTab && getCategoryKeyByTabAndGeo(topStoriesTab, sourceGeo);

  if (loose && !thumbnailUrls.length && !maxItems && !query) {
    thumbsPerPage = layoutBreakpoint === 'threecolumn' ? LOOSE_FIRST_STEP_THREE_COLUMN : LOOSE_FIRST_STEP;
  }

  useEffect(() => {
    if (maxItems) {
      return;
    }

    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          setIsInViewport(true);
        } else {
          setIsInViewport(false);
        }
      }
    };
    const observer = new IntersectionObserver(handleIntersect, {
      rootMargin: '700px'
    });

    if (moreButtonRef.current) {
      observer.observe(moreButtonRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    let aborted = false;

    clearShownList();
    setWaitingForMoreResults(true);
    (async () => {
      try {
        const urls = await getFreshDomainThumbnailUrls(thumbsPerPage, {
          repeatForever: !loose,
          sourceGeo
        });

        if (!aborted) {
          setThumbnailUrls(urls);
          setWaitingForMoreResults(false);
        }
      } catch (error) {
        setError(error);
      }
    })().catch();

    return () => {
      aborted = true;
    };
  }, [sourceGeo]);

  useEffect(() => {
    if (maxItems) {
      return;
    }

    let aborted = false;

    if (isInViewport && !waitingForMoreResults && !noMoreResults && !resultsLoading) {
      setWaitingForMoreResults(true);
      (async () => {
        try {
          const urls = await getFreshDomainThumbnailUrls(thumbsPerPage, {
            repeatForever: !loose && !gridLayout,
            sourceGeo
          });

          if (!aborted) {
            setThumbnailUrls([...thumbnailUrls, ...urls]);
            setWaitingForMoreResults(false);
          }
        } catch (error) {
          setError(error);
        }
      })().catch();
    }

    return () => {
      aborted = true;
    };
  }, [isInViewport, noMoreResults, resultsLoading]);

  const pages = thumbnailUrls.map(({url, thumb}, index) => {
    const host = new URL(url).host;

    return (
      <IndexPagePreview
        key={`${url}${index}`}
        url={url}
        onClick={() =>
          trackEvent(events.PAGE_VIEW_EVENT, {
            channel: host,
            id: host,
            layoutBreakpoint,
            position: index + 1,
            query: query || undefined,
            subchannel: query ? 'indexpage_search' : 'indexpage_home',
            title: host,
            topStoriesKey,
            url
          })
        }
        thumb={thumb}
        refreshStamp={refreshStamp}
        host={host}
      />
    );
  });

  const offsetRightPages =
    layoutBreakpoint === 'mobile' ? 0 : ((topStoriesPage || 0) + 1) * (layoutBreakpoint === 'fourcolumn' ? 4 : 2);

  const heading = (
    <div className={styles.heading} data-live-homepages>
      <span className={styles.live}>LIVE</span> Homepages
    </div>
  );

  const insertBillboards = (pageElements: ReactElement[]) => {
    if (layoutBreakpoint !== 'fourcolumn' || pageElements.length < BILLBOARD_STEP) {
      return pageElements;
    }

    const elementsWithAds = pageElements.slice();

    for (let billboardCount = 0; billboardCount < BILLBOARD_COUNT_MAX; billboardCount++) {
      const posToInsert = billboardCount + (billboardCount + 1) * BILLBOARD_STEP;

      if (elementsWithAds.length < posToInsert) {
        return elementsWithAds;
      }

      const positionId = `billboard_${billboardCount + 1}`;
      const billboardElement = (
        <div className={styles.billboardWrapper} key={positionId}>
          <AdSlot position="billboard" slotId={positionId} slotType="billboard" />
        </div>
      );

      elementsWithAds.splice(posToInsert, 0, billboardElement);
    }

    return elementsWithAds;
  };

  const mpuSlot = (
    <div className={styles.mobileMpuWrapper}>
      <AdSlot position="mpu_mobile" slotId="mpu_mobile_home" slotType="mpu" />
    </div>
  );

  return (
    <div
      className={classnames(
        styles.indexPagePreviewsWrapper,
        query && !gridLayout && styles.resultsContainer,
        maxItems && styles.splashRightPanel,
        !query && !maxItems && styles.splashContainer,
        gridLayout && styles.resultsGridContainer,
        loose && styles.looseGrid,
        SHOW_ALL_PAGES && styles.showAllPages
      )}
      data-top-stories-page={topStoriesPage}
    >
      {!hideHeading && !loose && heading}
      {!loose && <div className={styles.indexPagePreviews}>{pages}</div>}
      {loose && (
        <div className={styles.topStoriesAndRightWrapper}>
          {children}
          <div className={styles.splashRightPanel}>
            {layoutBreakpoint === 'mobile' && mpuSlot}
            {heading}
            {pages.slice(0, offsetRightPages)}
          </div>
        </div>
      )}
      {loose && (
        <div className={styles.loosePages} data-loose-pages>
          {insertBillboards(pages.slice(offsetRightPages))}
        </div>
      )}
      {!maxItems && <div className={styles.moreButton} ref={moreButtonRef} />}
      {error && <div className={styles.error}>{error.message}</div>}
    </div>
  );
};

export const IndexPagePreviews = withSizes<IndexPagePreviewsProps, IndexPagePreviewsProps>(mapSizesToProps)(
  IndexPagePreviewsBase
);
