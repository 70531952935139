import React from 'react';

import styles from './styles.css';

export const ImageGallery = ({urls}: {urls: string[]}) => {
  return (
    <div className={styles.wrapper}>
      {urls.length > 0 && urls.map((url, idx) => <img key={idx} src={url} className={styles.img} />)}
      {!urls.length && 'NONE'}
    </div>
  );
};
