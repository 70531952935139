export const getWebPushStatus = () => {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    switch (Notification.permission) {
      case 'default': {
        return 'eligible';
      }
      case 'denied': {
        return 'denied';
      }
      case 'granted': {
        return 'subscribed';
      }
    }
  }

  return 'not_supported';
};
