import axios from 'axios';

import {logger} from '../logger';

import {apiPath} from './index';

interface AbIdsResult {
  group: number;
  molABTest: string[];
  specifiedGroup: number;
  webUIOptions: {[key: string]: string};
}

export const getAbExperiments = async () => {
  try {
    const response = await axios.get<AbIdsResult>(apiPath('/abIDs'), {timeout: 5000});

    return (response.data && response.data.molABTest) || null;
  } catch (error) {
    logger.error('Failed to fetch abIDs');

    return null;
  }
};
