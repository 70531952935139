import {getFromStorage} from '../storage';

export const getPlatform = () => {
  let isDesktopRender = true;

  if ('matchMedia' in window) {
    isDesktopRender = window.matchMedia('(min-width: 768px)').matches;
  }

  if (getFromStorage('mobile-view') === 'DESKTOP') {
    isDesktopRender = true;
  }

  return isDesktopRender ? 'desktop' : 'mobile';
};
