import React from 'react';

export const IconNormalLayout = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31">
    <path
      fill="#D8D8D8"
      fillRule="evenodd"
      d="M0 0h7.2v8H0V0zm9 0h7.2v2H9V0zm0 4h5.4v2H9V4zm-9 7h7.2v8H0v-8zm9 0h7.2v2H9v-2zm0 4h5.4v2H9v-2zm-9 7h7.2v8H0v-8zm9 0h7.2v2H9v-2zm0 4h5.4v2H9v-2zM27 .812v12.376a.812.812 0 0 1-.812.812h-7.376a.812.812 0 0 1-.812-.812V.58c0-.32.26-.58.58-.58h7.84c.32 0 .58.26.58.58v.232zM25.5 4h-6v7.188c0 .448.364.812.812.812h4.376a.812.812 0 0 0 .812-.812V4zM27 17.812v12.376a.812.812 0 0 1-.812.812h-7.376a.812.812 0 0 1-.812-.812V17.58c0-.32.26-.58.58-.58h7.84c.32 0 .58.26.58.58v.232zM25.5 21h-6v7.188c0 .448.364.812.812.812h4.376a.812.812 0 0 0 .812-.812V21z"
    />
  </svg>
);
