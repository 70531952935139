import React from 'react';
import {render} from 'react-dom';
import URL from 'url-parse';
import Cookies from 'js-cookie';

// tslint:disable-next-line: no-import-side-effect
import './footerPages/footerStyles';
import {App} from './components/App';
import {setApiHost, getApiHost, TopStoriesCategory, TOP_STORIES_CATEGORIES, GEO_COUNTRIES, GeoCountry} from './api';
import {setGlobalClasses, setAdExperiments} from './api/ads';
import {setBaseShareUrl} from './api/social';
import {loadIfNeeded} from './gdpr';
import {initTracking} from './tracking';
import {getCategoryFromStorage, setABToStorage} from './storage';
import {initWebPush} from './webPush';
import {setStartingStoryId} from './api/externalAppState';
import {getDebugInfo as getDebugInfoSmartBanner, shouldShow as shouldShowSmartBanner} from './api/smartBanner';
import {getAbExperiments} from './api/abTest';

const isValidStartTab = (tab: string | undefined) => tab && (TOP_STORIES_CATEGORIES as string[]).includes(tab);

const REFERRER_COOKIE = 'X-REF';
const setReferrerCookie = () => {
  try {
    if (document.referrer) {
      Cookies.set(REFERRER_COOKIE, document.referrer);
    } else {
      Cookies.remove(REFERRER_COOKIE);
    }
  } catch (error) {
    // Ignore cookie errors
  }
};

const init = async () => {
  const element = document.getElementById('app-root');

  if (element) {
    const parsedUrl = new URL(window.location.href, true);
    let apiHost = element.dataset.apiHost;
    const showTabs = element.dataset.showTabs === 'true';
    const hideFacebook = element.dataset.hideFacebook === 'true';
    const showStickyBillboard = element.dataset.showStickyBillboard === 'true';
    const environment = element.dataset.environment as string;

    if (environment !== 'production' && parsedUrl.query && parsedUrl.query.debugApiHost) {
      apiHost = parsedUrl.query && parsedUrl.query.debugApiHost;
    }

    if (apiHost) {
      setApiHost(apiHost);
    } else {
      throw new Error('No api host set');
    }

    if (element.dataset.absoluteUrl) {
      setBaseShareUrl(element.dataset.absoluteUrl);
    }

    let homeRefreshInterval = element.dataset.homeRefreshInterval
      ? parseInt(element.dataset.homeRefreshInterval, 10)
      : null;

    if (parsedUrl.query && parsedUrl.query.debugHomeRefreshInterval) {
      homeRefreshInterval = parseInt(parsedUrl.query.debugHomeRefreshInterval, 10);
    }

    let startTab: TopStoriesCategory | undefined = getCategoryFromStorage() || undefined;

    if (parsedUrl.query && isValidStartTab(parsedUrl.query.startTab)) {
      startTab = parsedUrl.query.startTab as TopStoriesCategory;
    }

    const params = parsedUrl.query;
    let alteredParams = false;

    let overrideFirstStoryId: string | undefined;

    if (params && params.storyId) {
      overrideFirstStoryId = params.storyId;
      setStartingStoryId(params.storyId);
      delete params.storyId;
      alteredParams = true;
    }

    if (params && params.referringDomain && params.referringURL) {
      // This was used for adobe - keeping comment here in case it's needed again
      // setManualReferrer(params.referringDomain, params.referringURL);
      delete params.referringDomain;
      delete params.referringURL;
      alteredParams = true;
    }

    let abFromXP: string | null = null;

    if (params && params.abFromXP) {
      abFromXP = params.abFromXP;
      delete params.abFromXP;
      alteredParams = true;
    }

    let geoFromXP: GeoCountry | null = null;

    if (params && params.geoFromXP && (GEO_COUNTRIES as string[]).includes(params.geoFromXP)) {
      geoFromXP = params.geoFromXP as GeoCountry;
      delete params.geoFromXP;
      alteredParams = true;
    }

    if (alteredParams) {
      parsedUrl.set('query', params);
      window.history.replaceState({}, document.title, parsedUrl.href);
    }

    const abValue = setABToStorage(abFromXP || (parsedUrl.query && parsedUrl.query.debugABNumber));
    let abExperiments: string[] | null = null;

    if (abValue) {
      setApiHost(`${getApiHost()}/${abValue}`);
      abExperiments = await getAbExperiments();
      setAdExperiments(abExperiments);
    }

    setReferrerCookie();
    setGlobalClasses();

    const showDebugInfoSmartBanner = Boolean(parsedUrl.query && parsedUrl.query.debugInfoSmartBanner);
    const debugInfoSmartBanner = getDebugInfoSmartBanner();

    render(
      <App
        startingWithQuery={Boolean(parsedUrl.query && parsedUrl.query.q)}
        showTabs={showTabs}
        startTab={startTab}
        geoFromXP={geoFromXP}
        homeRefreshInterval={homeRefreshInterval}
        overrideFirstStoryId={overrideFirstStoryId}
        firstStoryIsMostRead={Boolean(parsedUrl.query && parsedUrl.query.mostRead)}
        firstStoryIsFBIA={Boolean(parsedUrl.query && parsedUrl.query.trendingFB)}
        hideFacebook={hideFacebook}
        showStickyBillboard={showStickyBillboard}
        showSmartBanner={shouldShowSmartBanner()}
        startWithStoriesOpen={Boolean(abExperiments && abExperiments.includes('StartTopStoriesOpen'))}
        topStoriesLazyScroll={Boolean(
          abExperiments &&
            (abExperiments.includes('StartTopStoriesOpenLazyB') ||
              abExperiments.includes('StartTopStoriesOpenControlLazyB'))
        )}
        debugInfoSmartBanner={showDebugInfoSmartBanner ? debugInfoSmartBanner : null}
        abValue={abValue}
      />,
      element
    );

    loadIfNeeded().catch();

    const {rtaHosts, rtaScript, hulkApiKey} = element.dataset;

    if (rtaHosts && rtaScript) {
      initTracking({
        abExperiments,
        hosts: rtaHosts,
        script: rtaScript
      })
        .then(() => {
          initWebPush(environment, hulkApiKey).catch();
        })
        .catch();
    }
  }
};

init().catch();
