import {getPlatform} from '../../../api/platform';

import {getGdprStatus} from './gdpr';
import {deleteFalsyValues, getHostname} from './utils';
import {getUserLocalServerDate} from './user';
import {getWebPushStatus} from './webPush';
import {getSearchParams} from './pageUrl';
import {getLastNonInternalReferrer} from './referrer';

export const getConfigProps = () => {
  const referrer = document.referrer;
  const sessionReferrer = getLastNonInternalReferrer() || referrer;
  const {ito, utm_source} = getSearchParams();
  let campaign;

  if (ito && !utm_source) {
    campaign = {
      medium: 'ito',
      source: ito
    };
  }

  const props = {
    campaign,
    gdprStatus: getGdprStatus(),
    itoExternalHit: ito,
    itoExternalSession: ito,
    pageUrl: location.href || '',
    referrer,
    referringDomain: sessionReferrer === 'undefined' ? sessionReferrer : getHostname(sessionReferrer),
    referringUrl: sessionReferrer,
    renderPlatform: getPlatform(),
    userAgent: navigator.userAgent,
    userLocalServerDate: getUserLocalServerDate(),
    webPushStatus: getWebPushStatus()
  };

  for (const dimension of ['itoExternalHit', 'itoExternalSession', 'referringUrl', 'referringDomain']) {
    // @ts-ignore
    if (!props[dimension]) {
      // @ts-ignore
      props[dimension] = 'undefined';
    }
  }

  return deleteFalsyValues(props);
};
