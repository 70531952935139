import axios from 'axios';

import {logger} from '../logger';

const LOCATION_FILE = '/locationjson.html';

export interface LocationResponse {
  Country: string;
  CITY: string;
  INEU: boolean;
  REGION_CODE: string;
}

let locationPromise: Promise<LocationResponse | undefined> | undefined;

const requestUserLocation = async () => {
  try {
    const {data} = await axios.get<LocationResponse>(LOCATION_FILE, {responseType: 'json'});

    return data || undefined;
  } catch (error) {
    logger.error('Failed to fetch user location json file', error);

    return undefined;
  }
};

export const getUserLocation = async () => {
  if (!locationPromise) {
    locationPromise = requestUserLocation();
  }

  return locationPromise;
};

export const clearPromise = () => {
  locationPromise = undefined;
};
