import React from 'react';
import classnames from 'classnames';

import PlusCircleSvg from './assets/js/plusCircle.svg';
import styles from './styles.css';

interface Prams {
  handleClick: () => void;
  text?: string;
  showText?: boolean;
}

export const MoreButton = ({handleClick, text, showText}: Prams) => {
  return (
    <button
      aria-label="show related"
      className={classnames(styles.relatedButton, showText && styles.showText)}
      onClick={handleClick}
    >
      <span>{text}</span>
      <PlusCircleSvg />
    </button>
  );
};
