import React, {useEffect, useState, useRef} from 'react';
import Ticker from 'react-ticker';
import classnames from 'classnames';

import {trackEvent, events} from '../../tracking';
import {popularSearches} from '../../api/search';
import {GeoCountry} from '../../api';
import {getFromStorage, setToStorage} from '../../storage';

import styles from './styles.css';

interface PopularSearchTickerProps {
  highlightIcon?: boolean;
  onSearch: (query: string) => void | Promise<void>;
  onVisibilityChange?: (isVisible: boolean) => void;
  sourceGeo: GeoCountry;
}

const SPEED_SETTINGS = [0.25, 0.5, 1, 2, 3, 4];
const REFRESH_INTERVAL = 5 * 60 * 1000;
const TICKER_PAUSE_STORAGE_KEY = 'ticker-paused';
const TICKER_SPEED_STORAGE_KEY = 'ticker-speed';

const isTouchEnabledDevice = 'ontouchstart' in window;

// To preserve position when changing speeds we need to use something like Ticker
// Can't do it with pure CSS animations because offset jumps around
export const PopularSearchTicker = ({
  highlightIcon = false,
  onSearch,
  sourceGeo,
  onVisibilityChange
}: PopularSearchTickerProps) => {
  const [popularSearchTerms, setPopularSearchTerms] = useState<string[] | null>(null);
  const [paused, setPausedState] = useState((getFromStorage(TICKER_PAUSE_STORAGE_KEY) as boolean) || false);
  const [focused, setFocused] = useState(false);
  const [speedMenuOpen, setSpeedMenuOpen] = useState(false);
  const [speed, setSpeedState] = useState((getFromStorage(TICKER_SPEED_STORAGE_KEY) as number) || 1);
  const [error, setError] = useState<Error | null>(null);
  const controlsRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const setPaused = (newValue: boolean) => {
    setPausedState(newValue);
    setToStorage(TICKER_PAUSE_STORAGE_KEY, newValue);
    trackEvent(newValue ? events.TICKER_PAUSE : events.TICKER_PLAY);
  };

  const setSpeed = (newValue: number) => {
    setSpeedState(newValue);
    setToStorage(TICKER_SPEED_STORAGE_KEY, newValue);
    trackEvent(events.TICKER_SPEED, {speed: newValue});
  };

  useEffect(() => {
    let aborted = false;
    (async () => {
      try {
        const terms = await popularSearches(sourceGeo);

        if (!aborted) {
          setPopularSearchTerms(terms);
        }
      } catch (error) {
        setError(error);
      }
    })().catch();

    const interval = window.setInterval(() => {
      (async () => {
        try {
          const terms = await popularSearches(sourceGeo);

          if (!aborted) {
            setPopularSearchTerms(terms);
          }
        } catch (error) {
          // Keep old terms
        }
      })().catch();
    }, REFRESH_INTERVAL);

    return () => {
      aborted = true;
      clearInterval(interval);
    };
  }, [sourceGeo]);

  useEffect(() => {
    const handleDocumentClick = (event: Event) => {
      const target = event.target;

      if (target && controlsRef.current && !controlsRef.current.contains(target as HTMLElement)) {
        setSpeedMenuOpen(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);

    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      if (!onVisibilityChange) {
        return;
      }

      for (const entry of entries) {
        if (entry.isIntersecting) {
          onVisibilityChange(true);
        } else {
          onVisibilityChange(false);
        }
      }
    };
    const observer = new IntersectionObserver(handleIntersect);

    if (wrapperRef.current) {
      observer.observe(wrapperRef.current);
    }

    return () => {
      document.removeEventListener('click', handleDocumentClick);
      observer.disconnect();
    };
  }, []);

  const handleSelectTerm = (term: string) => {
    onSearch(term);
    trackEvent(events.TICKER_SELECT, {term});
  };

  const tickerItems =
    popularSearchTerms &&
    popularSearchTerms.length &&
    popularSearchTerms.map((term) => (
      <div className={styles.searchTerm} key={term} onClick={() => handleSelectTerm(term)}>
        {term}
      </div>
    ));

  const handleSetSpeed = (speedSetting: number) => {
    setSpeed(speedSetting);
    setSpeedMenuOpen(false);
  };

  return (
    <div className={classnames(styles.wrapper, highlightIcon && styles.highlightIcon)} ref={wrapperRef}>
      <div className={styles.controls} ref={controlsRef}>
        <div className={styles.controlsHeading}>Most searched for right now</div>
        <button
          aria-label={'select speed'}
          className={classnames(styles.controlButton, speed < 1 && styles.controlButtonSmallFont)}
          onClick={() => setSpeedMenuOpen(!speedMenuOpen)}
        >
          &times;{speed}
        </button>
        <button
          aria-label="pause / play"
          className={classnames(styles.controlButton, paused ? styles.playButton : styles.pauseButton)}
          onClick={() => setPaused(!paused)}
        />
        <div className={classnames(styles.speedMenu, speedMenuOpen && styles.speedMenuOpen)}>
          <div className={styles.speedMenuHeading}>Speed</div>
          {SPEED_SETTINGS.map((speedSetting) => (
            <button
              aria-label={`${speedSetting}x speed`}
              className={styles.speedSetting}
              key={speedSetting}
              onClick={() => handleSetSpeed(speedSetting)}
            >
              &times;{speedSetting}
            </button>
          ))}
        </div>
      </div>
      <div
        className={styles.tickerWrapper}
        onMouseEnter={() => setFocused(true)}
        onMouseLeave={() => setFocused(false)}
      >
        {error && <div className={styles.error}>Error fetching top search terms</div>}
        {tickerItems && (
          <Ticker move={!paused && (!focused || isTouchEnabledDevice)} speed={speed * 5}>
            {() => (
              <>
                <div className={styles.scrollPage}>{tickerItems}</div>
              </>
            )}
          </Ticker>
        )}
      </div>
    </div>
  );
};
