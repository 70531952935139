import React, {useEffect, useRef, useState} from 'react';

import styles from './styles.css';

interface IndexPagePreviewProps {
  url: string;
  thumb: string;
  onClick: () => void;
  refreshStamp?: number | null;
  host: string;
}

export const IndexPagePreview = ({url, onClick, thumb, refreshStamp, host}: IndexPagePreviewProps) => {
  const elRef = useRef<HTMLAnchorElement>(null);
  const [isInViewport, setIsInViewport] = useState(false);

  useEffect(() => {
    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          setIsInViewport(true);
        } else {
          setIsInViewport(false);
        }
      }
    };
    const observer = new IntersectionObserver(handleIntersect, {
      rootMargin: '500px'
    });

    if (elRef.current) {
      observer.observe(elRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const imageUrl = `${thumb}${refreshStamp ? `?t=${refreshStamp}` : ''}`;
  const style = isInViewport
    ? {
        backgroundImage: `url("${imageUrl}")`
      }
    : {};

  return (
    <a
      className={styles.indexPagePreview}
      href={url}
      onClick={onClick}
      rel="noopener"
      style={style}
      target="_blank"
      ref={elRef}
    >
      <div className={styles.messageBox}>
        <div>Visit</div>
        <div>{host}</div>
      </div>
      <div className={styles.domainName}>{host}</div>
    </a>
  );
};
