import {getCategoryKeyByTabAndGeo} from '../api';
import {getExternalAppTab, getExternalAppGeo} from '../api/externalAppState';
import {getPlatform} from '../api/platform';

import {buildRTATrackMacro} from './macros/buildRTATrackMacro';

export const getOptions = async (baseEndpoint: string, apiKey?: string, environment?: string) => {
  const rtaTrackMacros = await buildRTATrackMacro();
  // Adobe visitor ID, no longer in use
  const visitorId = null;
  const visitorIds = visitorId ? [visitorId] : [];
  const platform = getPlatform();
  const appTab = getExternalAppTab();
  const appGeo = getExternalAppGeo();
  const channel = appTab && appGeo ? getCategoryKeyByTabAndGeo(appTab, appGeo) : null;

  // TODO: Uncomment whenever they want to enable GDPR
  // const profilingAllowed = await isProfilingAllowed();
  const profilingAllowed = true;

  const opts = {
    apiKey,
    baseEndpoint,
    environment,
    metadata: {
      channels: channel ? [channel] : [],
      organisation: 'newzit',
      platform,
      profilingAllowed,
      userAgent: navigator.userAgent,
      visitorIds
    },
    notificationMacros: {
      impression: [...rtaTrackMacros]
    },
    promptFrequency: '7d',
    swUrl: `/sw.js`
  };

  return opts;
};
