import React, {useEffect, useState, useRef, ReactElement} from 'react';
import classnames from 'classnames';
import URL from 'url-parse';
import withSizes from 'react-sizes';

import {trackEvent, events} from '../../tracking';
import {GeoCountry, TopStoriesCategory, getCategoryKeyByTabAndGeo} from '../../api';
import {topArticles, topArticlesGroupByArticleId, Result} from '../../api/search';
import {setExternalAppState} from '../../api/externalAppState';
import {LocationResponse} from '../../api/location';
import {shareFacebook} from '../../api/social';
import {isMobileDevice} from '../../utils';
import {setToStorage, CATEGORY_STORAGE_KEY} from '../../storage';
import {ResultItem} from '../ResultItem';
import {Spinner} from '../Spinner';
import {AdSlot} from '../AdSlot';
import {DebugModeState} from '../App';

import styles from './styles.css';
import {LessButton} from './LessButton';
import {MoreButton} from './MoreButton';

const RESULTS_PER_PAGE_DESKTOP = 20;
const RESULTS_PER_PAGE_MOBILE = 30;
const MPU_ENABLED = true;
const NUM_SHARE_BUTTONS = 7;
const SHOW_NYC_TAB_FOR_EVERYONE = false;
const MOST_READ_OVERRIDE_POSITION = 3;
const PRE_SCROLL_MOBILE_RESULT_COUNT = 10;
const MPU_STEP = 3;
const MPU_STEP_STARTOPEN = 2;

interface CategoryDisplay {
  label: string;
  value: TopStoriesCategory;
}

const categories: CategoryDisplay[] = [
  {label: 'Top Stories', value: 'news'},
  {label: 'Showbiz', value: 'entertainment'},
  {label: 'Sport', value: 'sport'},
  {label: 'New York', value: 'newYork'}
];

const excludeGroupsWithId = (filterResultId: string) => (result: Result) =>
  result.id !== filterResultId && (!result.more || !result.more.find((moreResult) => moreResult.id === filterResultId));

interface TopStoriesProps {
  onPageChange?: (newPage: number) => void;
  onTabChange?: (newTab: TopStoriesCategory) => void;
  onMoreLikeThis?: (story: Result) => void;
  sourceGeo?: GeoCountry;
  showTabs?: boolean;
  refreshStamp?: number | null;
  debug?: DebugModeState | null;
  startTab?: TopStoriesCategory;
  layoutBreakpoint?: 'mobile' | 'desktop';
  overrideFirstStoryId?: string;
  hideFacebook?: boolean;
  firstStoryIsMostRead?: boolean;
  firstStoryIsFBIA?: boolean;
  userLocation?: LocationResponse;
  startWithStoriesOpen?: boolean;
  topStoriesLazyScroll?: boolean;
}

const DEBUG_SCROLL_TRIGGER = window.location.search && window.location.search.match(/debugScrollTriggerOff=true/i);

const TopStoriesBase = ({
  onPageChange,
  onTabChange,
  onMoreLikeThis,
  sourceGeo = 'gb',
  showTabs = true,
  refreshStamp = null,
  debug = null,
  startTab,
  overrideFirstStoryId,
  layoutBreakpoint,
  hideFacebook = false,
  firstStoryIsMostRead,
  firstStoryIsFBIA,
  userLocation,
  startWithStoriesOpen,
  topStoriesLazyScroll
}: TopStoriesProps) => {
  const [results, setResults] = useState<Result[] | null>(null);
  const [overrideFirstStory, setOverrideFirstStory] = useState<Result | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTabState] = useState<TopStoriesCategory>(startTab || 'news');
  const [openedMoreIds, setOpenedMoreIds] = useState<string[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const headingRef = useRef<HTMLDivElement>(null);
  const scrollTriggerRef = useRef<HTMLDivElement>(null);
  const [scrollTriggered, setScrollTriggered] = useState(Boolean(DEBUG_SCROLL_TRIGGER || !topStoriesLazyScroll));
  const previousState = useRef<{currentTab: TopStoriesCategory; sourceGeo: GeoCountry} | undefined>();
  const showNYTab =
    ((userLocation && userLocation.Country === 'US' && ['NY', 'CT', 'NJ'].includes(userLocation.REGION_CODE)) ||
      SHOW_NYC_TAB_FOR_EVERYONE ||
      startTab === 'newYork') &&
    sourceGeo === 'us';
  const setCurrentTab = (newTab: TopStoriesCategory) => {
    if (onTabChange) {
      onTabChange(newTab);
    }
    setCurrentTabState(newTab);
  };
  const requestStories = ({
    tab,
    geo,
    previousTabKey
  }: {
    tab: TopStoriesCategory;
    geo: GeoCountry;
    previousTabKey?: string;
  }) => {
    let aborted = false;
    (async () => {
      try {
        const tabKey = getCategoryKeyByTabAndGeo(tab, geo);
        // Commenting this out to debug RTA anomaly
        // const isAutoRefresh = tabKey === previousTabKey;
        const isAutoRefresh = false;

        setExternalAppState('home', {
          geo,
          tab
        });

        const response = await topArticles(tabKey);

        // Preference is "last seen"
        setToStorage(CATEGORY_STORAGE_KEY, tab);

        if (isAutoRefresh) {
          trackEvent(events.TOP_STORIES_AUTOREFRESH, {channel: tabKey});
        } else {
          trackEvent(events.TAB_VIEW_EVENT, {
            autoRefresh: tabKey === previousTabKey ? 1 : 0,
            channel: tabKey,
            geo,
            previousTabKey,
            tab
          });
        }

        if (!aborted) {
          setResults(response && response.results);
          setLoading(false);

          if (response && response.results && !isAutoRefresh && startWithStoriesOpen && layoutBreakpoint === 'mobile') {
            const ids = response.results.map((result) => result.id);

            setOpenedMoreIds(overrideFirstStoryId ? [overrideFirstStoryId, ...ids] : ids);
          }
        }
      } catch (error) {
        if (!aborted) {
          setError(error);
        }
      }
    })().catch();

    return () => {
      aborted = true;
    };
  };
  const requestAndSetOverrideStory = async (id: string) => {
    try {
      const overrideStory = await topArticlesGroupByArticleId(id);

      if (overrideStory) {
        setOverrideFirstStory(overrideStory);
        setOpenedMoreIds((ids) =>
          layoutBreakpoint === 'mobile' && startWithStoriesOpen ? ids.concat(overrideStory.id) : [overrideStory.id]
        );
      }
    } catch (error) {
      // Ignore
    }
  };

  useEffect(() => {
    if (currentTab === 'newYork' && !showNYTab) {
      setCurrentTab('news');

      return;
    }

    setLoading(true);

    const previousTabKey =
      previousState.current &&
      getCategoryKeyByTabAndGeo(previousState.current.currentTab, previousState.current.sourceGeo);
    const hasChangedTab =
      previousState.current &&
      (previousState.current.currentTab !== currentTab || previousState.current.sourceGeo !== sourceGeo);

    if (!startWithStoriesOpen || layoutBreakpoint !== 'mobile') {
      setOpenedMoreIds([]);
    }

    const abort = requestStories({tab: currentTab, geo: sourceGeo, previousTabKey});

    previousState.current = {currentTab, sourceGeo};

    if (hasChangedTab) {
      setOverrideFirstStory(null);
    }

    return () => {
      abort();
    };
  }, [currentTab, sourceGeo, refreshStamp, layoutBreakpoint]);

  useEffect(() => {
    if (startWithStoriesOpen && layoutBreakpoint === 'mobile') {
      return;
    }

    const handleDocumentClick = (event: Event) => {
      const target = event.target as HTMLElement;

      if (
        target &&
        containerRef.current &&
        openedMoreIds.length &&
        !containerRef.current.contains(target) &&
        !target.closest(`.${styles.relatedButton}`)
      ) {
        setOpenedMoreIds([]);
      }
    };

    document.addEventListener('click', handleDocumentClick);

    if (openedMoreIds.length && isMobileDevice() && containerRef.current) {
      const openedElement = containerRef.current.querySelector('[data-is-opened="true"]');

      if (openedElement) {
        openedElement.scrollIntoView();
      }
    }

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [openedMoreIds, layoutBreakpoint]);

  useEffect(() => {
    if (!overrideFirstStoryId) {
      return;
    }

    requestAndSetOverrideStory(overrideFirstStoryId).catch();
  }, []);

  useEffect(() => {
    if (!results || scrollTriggered || layoutBreakpoint !== 'mobile') {
      return;
    }

    const handleIntersect = (entries: IntersectionObserverEntry[], currObserver: IntersectionObserver) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          setScrollTriggered(true);
          currObserver.disconnect();
        }
      }
    };
    const observer = new IntersectionObserver(handleIntersect, {
      rootMargin: '700px'
    });

    if (scrollTriggerRef.current) {
      observer.observe(scrollTriggerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [layoutBreakpoint, scrollTriggered, results]);

  if (!results) {
    return (
      <div className={styles.container}>
        {error && <div className={styles.error}>Error fetching Top Stories</div>}
        {!error && loading && <Spinner />}
      </div>
    );
  }

  let resultsToUse = results;

  if (overrideFirstStory) {
    const resultsWithoutOverride = results
      .filter(excludeGroupsWithId(overrideFirstStory.id))
      .slice(0, results.length - 1);

    resultsToUse = firstStoryIsMostRead
      ? resultsWithoutOverride
          .slice(0, MOST_READ_OVERRIDE_POSITION - 1)
          .concat([overrideFirstStory])
          .concat(resultsWithoutOverride.slice(MOST_READ_OVERRIDE_POSITION - 1))
      : [overrideFirstStory].concat(resultsWithoutOverride);
  }

  const isMobileInMobile = layoutBreakpoint === 'mobile';
  const resultsPerPage = isMobileInMobile ? RESULTS_PER_PAGE_MOBILE : RESULTS_PER_PAGE_DESKTOP;
  const maxPages = Math.floor(resultsToUse.length / resultsPerPage);

  if (onPageChange) {
    onPageChange(Math.max(maxPages - 1, 0));
  }

  const fullMaxResultCount = Math.max(maxPages, 1) * resultsPerPage;
  const maxResultCount =
    layoutBreakpoint === 'mobile' && !scrollTriggered ? PRE_SCROLL_MOBILE_RESULT_COUNT : fullMaxResultCount;
  const resultsInPage = resultsToUse.slice(0, maxResultCount);

  const handleToggleRelated = (id: string, url: string, position: number) => {
    const isOpened = openedMoreIds.includes(id);

    if (startWithStoriesOpen && layoutBreakpoint === 'mobile') {
      setOpenedMoreIds((openedIds) =>
        isOpened ? openedIds.filter((openedId) => openedId !== id) : openedIds.concat([id])
      );
    } else {
      setOpenedMoreIds(isOpened ? [] : [id]);
    }

    if (isOpened) {
      trackEvent(events.TOP_STORIES_RELATED_CLOSE, {url, position});
    } else {
      trackEvent(events.TOP_STORIES_RELATED_OPEN, {url, position});
    }
  };
  const handleResultTracking = (
    eventName: string,
    {id, url, title}: Result,
    subchannel: string,
    position: number,
    relatedPosition?: number,
    parentResult?: Result
  ) => {
    const topStoriesKey = getCategoryKeyByTabAndGeo(currentTab, sourceGeo);

    trackEvent(eventName, {
      channel: new URL(url).host,
      id,
      parentTitle: parentResult && parentResult.title,
      position,
      relatedPosition,
      subchannel,
      title,
      topStoriesKey,
      url
    });
  };
  const handleBigFacebookShare = (result: Result) => {
    shareFacebook(result.id, 'facebook_share_topstory');
    handleResultTracking(events.SOCIAL_SHARE_TOPSTORY_FACEBOOK_OVERRIDE, result, 'topstory_result_article', 1);
  };

  const insertMPUEveryNth = (elements: ReactElement[]) => {
    if (layoutBreakpoint !== 'mobile' || !MPU_ENABLED) {
      return elements;
    }

    const elementsWithAds = elements.slice();
    const mpuStep = startWithStoriesOpen ? MPU_STEP_STARTOPEN : MPU_STEP;

    for (let mpuCount = 0; mpuCount < elements.length / mpuStep - 1; mpuCount++) {
      const posToInsert = mpuCount + (mpuCount + 1) * mpuStep;

      if (elementsWithAds.length < posToInsert) {
        return elementsWithAds;
      }

      const positionId = `mpu_mobile_topstories_${mpuCount + 1}`;
      const mpuElement = (
        <div className={styles.mpuWrapper} key={positionId}>
          <AdSlot position="mpu_mobile" slotId={positionId} slotType="mpu" />
        </div>
      );

      elementsWithAds.splice(posToInsert, 0, mpuElement);
    }

    return elementsWithAds;
  };

  return (
    <div className={styles.container} data-page={Math.max(maxPages - 1, 0)}>
      {showTabs && (
        <div className={classnames(styles.heading)} ref={headingRef}>
          <div className={styles.buttonSelectors} data-current-tab={currentTab}>
            {categories
              .filter(({value}) => value !== 'newYork' || showNYTab)
              .map(({label, value}) => (
                <button
                  className={classnames(styles.tabSelector, currentTab === value && styles.selectedTab)}
                  data-tab-name={value}
                  disabled={loading}
                  key={value}
                  onClick={() => setCurrentTab(value)}
                >
                  {label}
                </button>
              ))}
          </div>
        </div>
      )}
      {!showTabs && <div className={styles.noTabsHeading}>TOP STORIES</div>}
      <div className={classnames(styles.results, loading && styles.loading)} ref={containerRef}>
        {!results.length && <div className={styles.noResults}>No results found</div>}
        {insertMPUEveryNth(
          resultsInPage.map((result, idx) => {
            const isOpened = openedMoreIds.includes(result.id);
            const nextIsOpened = resultsInPage[idx + 1] && openedMoreIds.includes(resultsInPage[idx + 1].id);
            const hasMore = Boolean(result.more && result.more.length);
            const hasMoreLikeThis = Boolean(result.moreLikeTerms && result.moreLikeTerms.length);
            const isManualGroup = result.override && result.expires;
            const isPushedGroup = !result.override && result.expires;
            const isOutgoingNotification = result.outgoingNotification;
            const isBreaking = result.notification;
            const isNotCovered = !result.coverages || (debug && !result.coverages.includes(debug.coverage));
            const handleToggle = () => handleToggleRelated(result.id, result.url, idx + 1);
            const isOverrideFirst = overrideFirstStory && overrideFirstStory.id === result.id;
            const showBigFacebookShare = !hideFacebook && isOverrideFirst;
            const buttonNode =
              ((hasMore || (isOverrideFirst && isOpened)) &&
                (isOpened ? (
                  <LessButton handleClick={handleToggle} />
                ) : (
                  <MoreButton handleClick={handleToggle} text={'See more versions'} showText={true} />
                ))) ||
              undefined;

            return (
              <div
                className={classnames(
                  styles.resultWrapper,
                  isOpened && styles.openedMore,
                  nextIsOpened && styles.nextIsOpened,
                  debug && isManualGroup && styles.manualGroup,
                  debug && isPushedGroup && styles.pushedGroup,
                  debug && isNotCovered && styles.notCovered,
                  debug && isOutgoingNotification && styles.isOutgoingNotification
                )}
                data-is-opened={isOpened}
                key={idx}
              >
                <ResultItem
                  headlineOnTop={startWithStoriesOpen && layoutBreakpoint === 'mobile'}
                  lazyLoadImage={layoutBreakpoint === 'mobile' && idx > 0}
                  breaking={isBreaking}
                  mostRead={firstStoryIsMostRead && Boolean(overrideFirstStory) && result.id === overrideFirstStoryId}
                  fbia={firstStoryIsFBIA && Boolean(overrideFirstStory) && result.id === overrideFirstStoryId}
                  onClick={() =>
                    handleResultTracking(events.PAGE_VIEW_EVENT, result, 'topstory_result_article', idx + 1)
                  }
                  onSiteClick={() =>
                    handleResultTracking(events.PAGE_VIEW_EVENT, result, 'topstory_result_site', idx + 1)
                  }
                  result={result}
                  showRelated={Boolean(debug)}
                  type="topStory"
                  buttonNode={buttonNode}
                  isGroupWinner={true}
                  showFacebookShare={idx < NUM_SHARE_BUTTONS && !hideFacebook}
                  onShareFacebook={() =>
                    handleResultTracking(
                      events.SOCIAL_SHARE_TOPSTORY_FACEBOOK,
                      result,
                      'topstory_result_article',
                      idx + 1
                    )
                  }
                />
                {isOpened && (
                  <div className={styles.moreModal}>
                    <div className={styles.relatedStoriesWrapper}>
                      <ResultItem
                        headlineOnTop={startWithStoriesOpen && layoutBreakpoint === 'mobile'}
                        lazyLoadImage={layoutBreakpoint === 'mobile' && idx > 0}
                        breaking={isBreaking}
                        mostRead={
                          firstStoryIsMostRead && Boolean(overrideFirstStory) && result.id === overrideFirstStoryId
                        }
                        fbia={firstStoryIsFBIA && Boolean(overrideFirstStory) && result.id === overrideFirstStoryId}
                        onClick={() =>
                          handleResultTracking(events.PAGE_VIEW_EVENT, result, 'topstory_result_article', idx + 1)
                        }
                        onSiteClick={() =>
                          handleResultTracking(events.PAGE_VIEW_EVENT, result, 'topstory_result_site', idx + 1)
                        }
                        result={result}
                        showRelated={Boolean(debug)}
                        type="topStory"
                        buttonNode={buttonNode}
                        isMainInModal={true}
                        isGroupWinner={true}
                        showFacebookShare={idx < NUM_SHARE_BUTTONS && !hideFacebook}
                        onShareFacebook={() =>
                          handleResultTracking(
                            events.SOCIAL_SHARE_TOPSTORY_FACEBOOK,
                            result,
                            'topstory_result_article',
                            idx + 1
                          )
                        }
                      />
                      {showBigFacebookShare && (
                        <button className={styles.bigFacebookShare} onClick={() => handleBigFacebookShare(result)}>
                          Share
                        </button>
                      )}
                      {result.more &&
                        result.more.map((moreResult, moreIdx: number) => (
                          <ResultItem
                            key={moreIdx}
                            lazyLoadImage={layoutBreakpoint === 'mobile' && idx > 0}
                            onClick={() =>
                              handleResultTracking(
                                events.PAGE_VIEW_EVENT,
                                moreResult,
                                'topstory_result_article',
                                idx + 1,
                                moreIdx + 1,
                                result
                              )
                            }
                            onSiteClick={() =>
                              handleResultTracking(
                                events.PAGE_VIEW_EVENT,
                                moreResult,
                                'topstory_result_site',
                                idx + 1,
                                moreIdx + 1,
                                result
                              )
                            }
                            result={moreResult}
                            showRelated={Boolean(debug)}
                            type="topStory"
                            isSmall={true}
                          />
                        ))}
                    </div>
                    {hasMoreLikeThis && (
                      <div className={styles.moreLikeThis} onClick={onMoreLikeThis && (() => onMoreLikeThis(result))}>
                        {hasMoreLikeThis && onMoreLikeThis !== undefined && <span>Search for more on this</span>}
                        {(hasMore || isOverrideFirst) && (
                          <LessButton
                            handleClick={(e: React.MouseEvent) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleToggle();
                            }}
                            ariaLabel="show related"
                          />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>
      {!scrollTriggered && layoutBreakpoint === 'mobile' && (
        <div className={styles.scrollTrigger} ref={scrollTriggerRef} />
      )}
    </div>
  );
};

const mapSizesToProps = ({width}: {width: number}): {layoutBreakpoint: 'mobile' | 'desktop'} => {
  if (width < 768) {
    return {layoutBreakpoint: 'mobile'};
  }

  return {layoutBreakpoint: 'desktop'};
};

export const TopStories = withSizes<TopStoriesProps, TopStoriesProps>(mapSizesToProps)(TopStoriesBase);
