import {loadScript} from '../utils';

export interface HulkGlobal {
  init: () => Promise<void>;
  showPrompt: () => Promise<void>;
  fetchApplicationServerKey: () => Promise<{parsed: string; raw: Uint8Array}>;
  isEligible: () => boolean;
  isWebPushSupported: () => boolean;
  // tslint:disable-next-line no-any
  Register: any;
}

interface HulkWindow extends Window {
  hulk?: HulkGlobal;
}

const SCRIPT_ELEMENT_ID = 'hulk-register-script';

export const getHulk = async (baseEndpoint: string) => {
  if (!(window as HulkWindow).hulk) {
    try {
      await loadScript(`${baseEndpoint}v1/static/latest/mol-fe-web-push-browser-register/register.js`, {
        id: SCRIPT_ELEMENT_ID
      });
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.error('Error trying to load HULK SDK');
    }
  }

  return (window as HulkWindow).hulk;
};
