import React, {useRef, useEffect} from 'react';
import classnames from 'classnames';

import {AdSlot} from '../AdSlot';

import styles from './styles.css';

interface StickyBillboardProps {
  size: 'big' | 'small';
  onVisibilityChange: (isVisible: boolean) => void;
}

export const StickyBillboard = ({size, onVisibilityChange}: StickyBillboardProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          onVisibilityChange(true);
        } else {
          onVisibilityChange(false);
        }
      }
    };
    const observer = new IntersectionObserver(handleIntersect);

    if (wrapperRef.current) {
      observer.observe(wrapperRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [size]);

  return (
    <div className={classnames(styles.wrapper, styles[size])} ref={wrapperRef}>
      <AdSlot position="stickyBillboard" slotId="stickyBillboard" slotType="stickyBillboard" size={size} />
    </div>
  );
};
